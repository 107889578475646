import React, { useContext } from "react"
import { Link } from "react-router-dom"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {
  BsFillPersonFill,
  BsFileEarmarkPdfFill,
  BsFillFileEarmarkTextFill,
  BsFillFileEarmarkPlusFill,
  BsFileEarmarkMedicalFill,
} from "react-icons/bs"
import { AuthContext, IAuthContext } from "@/lib/auth"
import CheckSheet from "@/pdf/check_sheet.pdf"

const App = () => {
  const auth = useContext(AuthContext)

  return (
    <header id="header">
      <Container fluid>
        <Row>
          <Col xl={4} md xs={12} className="d-flex align-items-center justify-content-start px-1 px-sm-3 py-2">
            <h1 className="logo">安全衛生点検チェックシートWEB版</h1>
          </Col>
          <Col xl md={2} xs={4} className="d-flex align-items-center justify-content-start py-2">
            <Link to="/" className="md-w-100 xl-w-100">
              <Button variant="primary" className="text-white md-w-100 xl-w-100 px-0 px-xl-3">
                <BsFillFileEarmarkTextFill size={18} className="me-1" />
                用紙一覧
              </Button>
            </Link>
          </Col>
          <Col xl md={2} xs={4} className="d-flex align-items-center justify-content-start">
            <Link to="/report/create" className="md-w-100 xl-w-100">
              <Button variant="info" className="text-white md-w-100 xl-w-100 px-0 px-xl-3">
                <BsFillFileEarmarkPlusFill size={18} className="me-1" />
                新規登録
              </Button>
            </Link>
          </Col>
          <Col xl md={2} xs={4} className="d-flex align-items-center justify-content-start">
            <Link to="/report/draft" className="md-w-100 xl-w-100">
              <Button variant="secondary" className="text-white md-w-100 xl-w-100 px-0 px-xl-3">
                <BsFileEarmarkMedicalFill size={18} className="me-1" />
                下書き一覧
              </Button>
            </Link>
          </Col>
          <Col xl={2} md={2} xs={4} className="d-flex align-items-center justify-content-start">
            <Link target="_blank" to={CheckSheet} rel="noreferrer" className="md-w-100 xl-w-100">
              <Button variant="danger" className="text-white md-w-100 xl-w-100 px-0 px-xl-3">
                <BsFileEarmarkPdfFill size={18} className="me-1" />
                チェックシート紙版
              </Button>
            </Link>
          </Col>
          <Col xl={3} md={12} xs={12} className="d-flex align-items-center justify-content-end ml-auto">
            {ShowSignoutBtn({
              auth,
            })}
          </Col>
        </Row>
      </Container>
    </header>
  )
}

const ShowSignoutBtn = (props: { auth: IAuthContext }) => {
  if (props.auth.currentUser) {
    return (
      <>
        <Link to={`/auth/signout`}>
          <BsFillPersonFill size={23} className="me-1" />
          {props.auth.currentUser?.username} さん ログアウト
        </Link>
      </>
    )
  } else {
    return <></>
  }
}

export default App
