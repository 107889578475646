import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Auth } from "@aws-amplify/auth"
import Alert from "react-bootstrap/Alert"

export default () => {
  const history = useNavigate()

  const signout = async () => {
    await Auth.signOut()
    history("/")
  }

  useEffect(() => {
    signout()
    setTimeout(() => {
      history("/")
    }, 1000)
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      <Alert variant="primary">ログアウトしました</Alert>
      <p className="text-muted">Go to the Top</p>
    </React.Fragment>
  )
}
