import { IOption, IQuestionError, IReportComments, IReportQuestion, isValidReportComments } from "@/lib/interface"
import { parseComment } from "@/lib/utils"
import React, { useEffect } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Image from "react-bootstrap/Image"
import Row from "react-bootstrap/Row"
import Dropzone from "react-dropzone"
import { BsImage } from "react-icons/bs"
import { Link as Scroll } from "react-scroll"
import QuestionAnswer from "../common/question-answer"

function App(props: any) {
  useEffect(() => {
    let commentsArray: IReportComments[] = [{ author: "", comment: "" }]
    try {
      const spritCommentData = props.report.comment.split("; ")
      commentsArray = spritCommentData.map((section: string) => parseComment(section))
      commentsArray = commentsArray.filter(isValidReportComments)
      props.reportDispatch({
        type: "COMMENT",
        payload: commentsArray,
      })
    } catch (error) {
      if (typeof props.report.comment === "string") {
        props.reportDispatch({
          type: "COMMENT",
          payload: [{ author: "", comment: props.report.comment }],
        })
      }
    }
  }, [props.report.comment])
  useEffect(() => {
    let requestComment: IReportComments[] = [{ author: "", comment: "" }]
    try {
      requestComment = props.report.request_comment.split("; ").map((section: string) => parseComment(section))
      requestComment = requestComment.filter(isValidReportComments)
      props.reportDispatch({
        type: "REQUEST_COMMENT",
        payload: requestComment,
      })
    } catch (error) {
      if (typeof props.report.request_comment === "string") {
        props.reportDispatch({
          type: "REQUEST_COMMENT",
          payload: [{ author: "", comment: props.report.request_comment }],
        })
      }
    }
  }, [props.report.request_comment])

  return (
    <>
      <hr />
      <Row className="align-items-center">
        <Col sm={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          点検分類
        </Col>
        <Col sm={12} lg={10}>
          <Form>
            <div className="text-danger">{props.report?.errors?.inspections}</div>
            <Row>
              {props.inspections?.map((inspection: IOption, index: number) => {
                return (
                  <Col xs={6} xl={4} key={`inspection-${index}`}>
                    <Form.Check
                      inline
                      type="switch"
                      id={`inspection-${index}`}
                      name="inspection"
                      label={inspection.name}
                      value={inspection.id}
                      checked={props.report.inspections?.includes(inspection.id)}
                      onChange={(e) =>
                        props.reportDispatch({
                          type: "INSPECTION_ID",
                          payload: e.currentTarget.value,
                        })
                      }
                      onBlur={(e) =>
                        props.reportDispatch({
                          type: "VALID_INSPECTION_ID",
                          payload: e.currentTarget.value,
                        })
                      }
                    />
                    <Scroll to={inspection.id || ""} smooth={true} duration={100} offset={-60}>
                      <span className="text-primary text-decoration-underline">↓</span>
                    </Scroll>
                  </Col>
                )
              })}
            </Row>
          </Form>
        </Col>
      </Row>
      <hr />

      <div className="mb-3 fw-bold">チェックリスト</div>

      <Row className="bg-dark text-white text-center fw-bold sticky-top py-2 d-md-none">
        <Col xs={1} />
        <Col xs={4}>点検分類</Col>
        <Col xs={6}>
          <span className="d-inline-block bg-white text-success rounded px-2">大項目</span> - 項目名
        </Col>
        <Col xs={1} />
      </Row>

      <Row className="bg-dark text-white text-center fw-bold sticky-top py-2 d-none d-md-flex">
        <Col md={1} className="px-1">
          No
        </Col>
        <Col md={2} className="px-1">
          点検分類
        </Col>
        <Col md={2} className="px-1">
          大項目
        </Col>
        <Col md={4} className="px-1">
          項目名
        </Col>
        <Col md={3} className="px-1">
          チェック
        </Col>
      </Row>

      {props.report.questions?.map((question: IReportQuestion, questionIndex: number) => {
        const beforeResultId = props?.report?.before_questions?.find(
          (beforeQuestion: IReportQuestion) => question.id === beforeQuestion.id
        )?.result_id
        return (
          <React.Fragment key={`question-${questionIndex}`}>
            <Row
              id={question.inspection_id}
              className={`align-items-center py-2 ${questionIndex % 2 === 0 ? "" : "bg-secondary bg-opacity-10"}`}
              style={{ minHeight: "2.5em" }}
            >
              <Col xs={1} className="text-center px-1">
                {questionIndex + 1}.
              </Col>
              <Col xs={3} md={2} className="px-1">
                {question.inspection_name}
                {question.department_name}
              </Col>
              <Col xs={8} className="d-md-none">
                <span className="d-block text-success fw-bold">{question.class_name}</span>
                {question.name}
              </Col>
              <Col md={2} className="d-none d-md-flex px-1">
                {question.class_name}
              </Col>
              <Col md={4} className="d-none d-md-flex px-1">
                {question.name}
              </Col>
              <Col xs={{ span: 8, offset: 4 }} md={{ span: 3, offset: 0 }} className="text-center px-1 mt-2 mt-md-0">
                <Row className="mx-auto">
                  <Col xs={3}>
                    <input
                      type="radio"
                      className="btn-check"
                      id={`question-${questionIndex}-result-slash`}
                      name={`question-${questionIndex}-result`}
                      checked={"RESULT_SLASH" === question.result_id}
                      value={"RESULT_SLASH"}
                      onChange={(e) =>
                        props.reportDispatch({
                          type: "RESULT_ID",
                          result_id: e.currentTarget.value,
                          question_id: question.id,
                        })
                      }
                    />
                    <QuestionAnswer
                      resultId={"RESULT_SLASH"}
                      htmlFor={`question-${questionIndex}-result-slash`}
                      before={beforeResultId}
                    />
                  </Col>
                  <Col xs={3}>
                    <input
                      type="radio"
                      className="btn-check"
                      id={`question-${questionIndex}-result-circle`}
                      name={`question-${questionIndex}-result`}
                      checked={"RESULT_CIRCLE" === question.result_id}
                      value={"RESULT_CIRCLE"}
                      onChange={(e) =>
                        props.reportDispatch({
                          type: "RESULT_ID",
                          result_id: e.currentTarget.value,
                          question_id: question.id,
                        })
                      }
                    />
                    <QuestionAnswer
                      resultId={"RESULT_CIRCLE"}
                      htmlFor={`question-${questionIndex}-result-circle`}
                      before={beforeResultId}
                    />
                  </Col>
                  <Col xs={3}>
                    <input
                      type="radio"
                      className="btn-check"
                      id={`question-${questionIndex}-result-triangle`}
                      name={`question-${questionIndex}-result`}
                      checked={"RESULT_TRIANGLE" === question.result_id}
                      value={"RESULT_TRIANGLE"}
                      onChange={(e) =>
                        props.reportDispatch({
                          type: "RESULT_ID",
                          result_id: e.currentTarget.value,
                          question_id: question.id,
                        })
                      }
                    />
                    <QuestionAnswer
                      resultId={"RESULT_TRIANGLE"}
                      htmlFor={`question-${questionIndex}-result-triangle`}
                      before={beforeResultId}
                    />
                  </Col>
                  <Col xs={3}>
                    <input
                      type="radio"
                      className="btn-check"
                      id={`question-${questionIndex}-result-cross`}
                      name={`question-${questionIndex}-result`}
                      checked={"RESULT_CROSS" === question.result_id}
                      value={"RESULT_CROSS"}
                      onChange={(e) =>
                        props.reportDispatch({
                          type: "RESULT_ID",
                          result_id: e.currentTarget.value,
                          question_id: question.id,
                        })
                      }
                    />
                    <QuestionAnswer
                      resultId={"RESULT_CROSS"}
                      htmlFor={`question-${questionIndex}-result-cross`}
                      before={beforeResultId}
                    />
                  </Col>
                </Row>
              </Col>
              {["RESULT_TRIANGLE", "RESULT_CROSS"].includes(question?.result_id || "") && (
                <div className="mx-0 px-xs-0 px-md-5 mt-2">
                  <div
                    className={`alert ${question?.result_id == "RESULT_TRIANGLE" ? "alert-warning" : "alert-danger"}`}
                  >
                    <Row className="mx-0 align-items-center">
                      <Col className="fw-bold aling-middle mb-2 mb-xl-0">指導または是正内容</Col>
                      <Col xl={10} className="">
                        <Form>
                          <div className="text-danger">
                            {
                              props.report?.errors?.questions?.find((error: IQuestionError) => {
                                return error.question_id === question.id
                              })?.comment
                            }
                          </div>
                          <Form.Control
                            size="sm"
                            as="textarea"
                            rows={3}
                            type="text"
                            name="question_comment"
                            id="question_comment"
                            value={question.comment || ""}
                            placeholder="100文字以内"
                            disabled={props.progress === props.Progress.Attempting}
                            onChange={(e) =>
                              props.reportDispatch({
                                type: "QUESTION_COMMENT",
                                payload: e.currentTarget.value,
                                question_id: question.id,
                              })
                            }
                            maxLength={100}
                          />
                        </Form>
                      </Col>
                    </Row>
                    <hr />
                    <Row className="mx-0 align-items-center">
                      <Col className="fw-bold aling-middle mb-2 mb-xl-0">画像アップロード</Col>
                      <Col xl={10} className="">
                        <Dropzone
                          accept={{ "image/*": [] }}
                          onDrop={(file: File[]) =>
                            props.reportDispatch({
                              type: "QUESTION_IMAGE",
                              payload: file,
                              question_id: question.id,
                            })
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section className="dropzone border rounded text-center p-4 bg-light">
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <h3>
                                  <BsImage size={60} className="text-secondary" />
                                </h3>
                                <div className="my-3 h3">アップロードしたい画像をここにドラッグ＆ドロップ</div>
                                <span className="btn btn-primary">写真を撮る、もしくはファイルを選択</span>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        <span className="fst-italic">アップロードする画像は20Mまで。PNG \ JPEG \ JPG \ GIF 形式</span>
                      </Col>
                    </Row>
                    {question.image_file?.name && (
                      <>
                        <hr />
                        <Row className="mx-0 align-items-center">
                          <Col className="fw-bold aling-middle">アップロード画像確認</Col>
                          <Col xl={10} className="">
                            <div className="bg-white border p-2 d-inline-block">
                              <img src={URL.createObjectURL(question.image_file)} className="mw-100" />
                            </div>
                            <div>
                              <button
                                onClick={() =>
                                  props.reportDispatch({
                                    type: "DEL_PREVIEW_QUESTION_IMAGE",
                                    question_id: question.id,
                                    oldImages: props.oldImages,
                                  })
                                }
                                className="btn btn-warning mt-3"
                              >
                                キャンセル
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    {!question.image_file?.name && question.image && (
                      <>
                        <hr />
                        <Row className="mx-0 align-items-center">
                          <Col className="fw-bold aling-middle">アップロード済み画像</Col>
                          <Col xl={10} className="">
                            <div className="bg-white border p-2 d-inline-block">
                              <Image className="mw-100" src={`${process.env.MEDIA_DOMAIN}${question.image}`} />
                            </div>
                            <div>
                              <button
                                onClick={() =>
                                  props.reportDispatch({
                                    type: "DEL_QUESTION_IMAGE",
                                    question_id: question.id,
                                  })
                                }
                                className="btn btn-danger mt-3"
                              >
                                削除
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </div>
              )}
            </Row>
          </React.Fragment>
        )
      })}
      <hr />
      <Row className="align-items-center mb-3">
        <Col lg={2} className="fw-bold">
          総評
        </Col>
        <Col lg={10}>
          {Array.isArray(props.report?.comment) &&
            props.report?.comment?.map((commentObject: IReportComments, index: number) => (
              <Row key={`comment-${index}`} className="mb-2">
                <Col md={2} className="mb-2">
                  {Array.isArray(props?.report?.errors?.comment) &&
                    index >= 0 &&
                    index < props?.report?.errors?.comment?.length && (
                      <div className="text-danger mb-3">{props?.report?.errors?.comment[index]?.author}</div>
                    )}
                  <Form.Control
                    size="sm"
                    as="input"
                    value={commentObject.author}
                    placeholder="記入者"
                    onChange={(e) =>
                      props.reportDispatch({
                        type: "COMMENT_AUTHOR",
                        payload: e.currentTarget.value,
                        index: index,
                      })
                    }
                    onBlur={(e) =>
                      props.reportDispatch({
                        type: "VALID_COMMENT_AUTHOR",
                        payload: e.currentTarget.value,
                        index: index,
                      })
                    }
                  />
                </Col>
                <Col md={9}>
                  {Array.isArray(props?.report?.errors?.comment) &&
                    index >= 0 &&
                    index < props?.report?.errors?.comment?.length && (
                      <div className="text-danger mb-3">{props?.report?.errors?.comment[index]?.comment}</div>
                    )}
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={3}
                    value={commentObject.comment}
                    placeholder="総評"
                    disabled={props.progress === props.Progress.Attempting}
                    onChange={(e) =>
                      props.reportDispatch({
                        type: "COMMENT",
                        payload: e.currentTarget.value,
                        index: index,
                      })
                    }
                    onBlur={(e) =>
                      props.reportDispatch({
                        type: "VALID_COMMENT",
                        payload: e.currentTarget.value,
                        index: index,
                      })
                    }
                  />
                </Col>
                {props.report.comment.length > 1 && (
                  <Col mb={1}>
                    <Button
                      size="sm"
                      className="btn-danger mt-2"
                      disabled={props.progress === props.Progress.Attempting}
                      onClick={() =>
                        props.reportDispatch({
                          type: "COMMENT_DEL",
                          index: index,
                        })
                      }
                    >
                      削除
                    </Button>
                  </Col>
                )}
              </Row>
            ))}
          {props.report.comment.length < 10 && (
            <Button
              size="sm"
              className="mt-3"
              disabled={props.progress === props.Progress.Attempting}
              onClick={() => props.reportDispatch({ type: "COMMENT_ADD" })}
            >
              追加
            </Button>
          )}
          <span className="fst-italic d-block"> , : ; &quot; &apos;は入力できません</span>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col lg={2} className="fw-bold mb-2 mb-lg-0">
          現場からの要望事項
        </Col>
        <Col lg={10}>
          <Form>
            {Array.isArray(props?.report?.request_comment) &&
              props.report.request_comment.map((commentObject: IReportComments, index: number) => (
                <Row key={`request-comment-${index}`} className="mb-3 align-items-center">
                  <Col md={2} className="mb-2">
                    {Array.isArray(props?.report?.errors?.request_comment) &&
                      index >= 0 &&
                      index < props?.report?.errors?.request_comment?.length && (
                        <div className="text-danger mb-3">{props?.report?.errors?.request_comment[index]?.author}</div>
                      )}

                    <Form.Control
                      size="sm"
                      as="input"
                      value={commentObject.author}
                      placeholder="記入者"
                      onChange={(e) =>
                        props.reportDispatch({
                          type: "REQUEST_COMMENT_AUTHOR",
                          payload: e.currentTarget.value,
                          index: index,
                        })
                      }
                      onBlur={(e) =>
                        props.reportDispatch({
                          type: "VALID_REQUEST_COMMENT_AUTHOR",
                          payload: e.currentTarget.value,
                          index: index,
                        })
                      }
                    />
                  </Col>
                  <Col md={9}>
                    {Array.isArray(props?.report?.errors?.request_comment) &&
                      index >= 0 &&
                      index < props?.report?.errors?.request_comment?.length && (
                        <div className="text-danger mb-3">{props?.report?.errors?.request_comment[index]?.comment}</div>
                      )}
                    <Form.Control
                      size="sm"
                      as="textarea"
                      rows={3}
                      value={commentObject.comment}
                      placeholder="現場からの要望事項"
                      disabled={props.progress === props.Progress.Attempting}
                      onChange={(e) =>
                        props.reportDispatch({
                          type: "REQUEST_COMMENT",
                          payload: e.currentTarget.value,
                          index: index,
                        })
                      }
                      onBlur={(e) =>
                        props.reportDispatch({
                          type: "VALID_REQUEST_COMMENT",
                          payload: e.currentTarget.value,
                          index: index,
                        })
                      }
                    />
                  </Col>
                  {props.report.request_comment.length > 1 && (
                    <Col md={1}>
                      <Button
                        size="sm"
                        className="btn-danger"
                        disabled={props.progress === props.Progress.Attempting}
                        onClick={() =>
                          props.reportDispatch({
                            type: "REQUEST_COMMENT_DEL",
                            index: index,
                          })
                        }
                      >
                        削除
                      </Button>
                    </Col>
                  )}
                </Row>
              ))}
            {props.report.request_comment.length < 10 && (
              <Button
                size="sm"
                className="mt-3"
                disabled={props.progress === props.Progress.Attempting}
                onClick={() => props.reportDispatch({ type: "REQUEST_COMMENT_ADD" })}
              >
                追加
              </Button>
            )}
          </Form>
          <span className="fst-italic d-block"> , : ; &quot; &apos;は入力できません</span>
        </Col>
      </Row>
    </>
  )
}

export default App
