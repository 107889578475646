import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as api from "@/lib/api"
import { IReport } from "@/lib/interface"
import { errorHandle } from "@/lib/system"

export default (props: any) => {
  const [errorMessage, setErrorMessage] = useState<string>("")
  const history = useNavigate()

  useEffect(() => {
    setErrorMessage("")
  }, [props.show])

  const handleDelete = async () => {
    try {
      const json = await api.delReportDb(props.delReportId)
      if (props.reports) {
        props.setReports(
          props.reports?.filter((report: IReport) => {
            return report.id !== props.delReportId
          })
        )
      } else {
        history("/")
      }
      console.log(json)
      props.onHide()
    } catch (err) {
      setErrorMessage(errorHandle(err))
    }
  }

  return (
    <Modal className="mt-5" show={props.show} onHide={props.onHide}>
      <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      <Modal.Body>
        <div>{errorMessage}</div>
        <Row>
          <div>
            <p className="alert alert-danger">
              削除しますか？
              <br />
              削除すると元に戻せません
            </p>
          </div>
          <Col lg={6}>
            <Button onClick={() => handleDelete()} className="btn-sm w-100">
              <span>削除する</span>
            </Button>
          </Col>
          <Col lg={6}>
            <Button onClick={props.onHide} className="btn-sm w-100" variant="secondary">
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
