import React from "react"
import { Routes, BrowserRouter as Router, Route } from "react-router-dom"

import MyRoute from "./lib/custom-route"

import SignIn from "./pages/auth/signin"
import SignOut from "./pages/auth/signout"
import ReportIndex from "./pages/report/index"
import ReportCreate from "./pages/report/create"
import ReportEdit from "./pages/report/create"
import ReportDrafts from "./pages/report/draft"
import ReportDetail from "./pages/report/detail"
import ReportPdf from "./pages/report/pdf"
import PageNotFound from "./pages/page-not-found"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MyRoute Component={<ReportIndex />} wrap auth contents id="report-index" />} />
        <Route path="auth">
          <Route path="signin" element={<MyRoute Component={<SignIn />} wrap contents id="auth-signin" />} />
          <Route path="signin/:id" element={<MyRoute Component={<SignIn />} wrap contents id="auth-signin" />} />
          <Route path="signout" element={<MyRoute Component={<SignOut />} wrap auth contents id="auth-signout" />} />
        </Route>
        <Route path="report">
          <Route
            path="create"
            element={<MyRoute Component={<ReportCreate />} wrap auth contents id="report-create" />}
          />
          <Route
            path="draft"
            element={<MyRoute Component={<ReportDrafts />} wrap auth contents id="report-drafts" />}
          />
          <Route path=":id" element={<MyRoute Component={<ReportDetail />} wrap auth contents id="report-detail" />} />
          <Route path=":id/pdf" element={<MyRoute Component={<ReportPdf />} wrap auth contents id="report-pdf" />} />
          <Route path=":id/edit" element={<MyRoute Component={<ReportEdit />} wrap auth contents id="report-edit" />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  )
}

export default App
