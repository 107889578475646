import React from "react"

export default (props: any) => {
  return (
    <>
      <input
        type="checkbox"
        className="btn-check"
        id={props.id}
        name={props.name}
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
      <label className="btn btn-outline-success w-100 px-0" htmlFor={props.id}>
        {props.label}
      </label>
    </>
  )
}
