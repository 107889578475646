import React from "react"
import { BsSlashLg, BsCircle, BsXLg, BsTriangle } from "react-icons/bs"

interface AnswerProps {
  disabled?: boolean
  resultId: string
  htmlFor?: string
  before?: string
}

interface IAnswerColors {
  [key: string]: string
}

const answerColors: IAnswerColors = {
  RESULT_SLASH: "dark",
  RESULT_CIRCLE: "primary",
  RESULT_CROSS: "danger",
  RESULT_TRIANGLE: "warning",
}

export default (props: AnswerProps) => {
  const answerColor = answerColors[props.resultId]
  const before = props.before ? props.before : ""
  if (props.disabled) {
    return (
      <>
        <span className={`btn btn-${answerColor} text-white py-2`}>
          <Qa resultId={props?.resultId} />
        </span>
      </>
    )
  } else {
    return (
      <>
        <label
          className={`btn btn-outline-${answerColor} py-2 ${before === props.resultId ? "btn-before-result" : ""}`}
          htmlFor={props.htmlFor}
        >
          <Qa resultId={props?.resultId} />
        </label>
      </>
    )
  }
}

const Qa = (props: AnswerProps) => {
  interface IIcons {
    [key: string]: JSX.Element
  }

  const icons: IIcons = {
    RESULT_SLASH: <BsSlashLg size={20} className="align-text-bottom" />,
    RESULT_CIRCLE: <BsCircle size={20} className="align-text-bottom" />,
    RESULT_TRIANGLE: <BsTriangle size={20} className="align-text-bottom" />,
    RESULT_CROSS: <BsXLg size={20} className="align-text-bottom" />,
  }
  return icons[props.resultId] || <></>
}
