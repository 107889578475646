import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as api from "@/lib/api"
import { errorHandle } from "@/lib/system"

type DeleteDraftModalProps = {
  draftId: string
  onHide: () => void
  show: boolean
  delDraftReport: (id: string) => void
}
export default (props: DeleteDraftModalProps) => {
  const [errorMessage, setErrorMessage] = useState<string>("")

  useEffect(() => {
    setErrorMessage("")
  }, [props.show])

  return (
    <Modal className="mt-5" show={props.show} onHide={props.onHide}>
      <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      <Modal.Body>
        <div>{errorMessage}</div>
        <Row>
          <div>
            <p className="alert alert-danger">
              下書きを削除しますか？
              <br />
              削除すると元に戻せません
            </p>
          </div>
          <Col lg={6}>
            <Button
              onClick={() => {
                props.delDraftReport(props.draftId)
                props.onHide()
              }}
              className="btn-sm w-100"
            >
              <span>削除する</span>
            </Button>
          </Col>
          <Col lg={6}>
            <Button onClick={() => props.onHide()} className="btn-sm w-100" variant="secondary">
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
