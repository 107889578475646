import React, { useState } from "react"

import { AuthProvider } from "./lib/auth"

import Pages from "./route"

function App() {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <div id="container" className="">
      <AuthProvider onLoaded={setIsLoaded}>{isLoaded && <Pages />}</AuthProvider>
    </div>
  )
}

export default App
