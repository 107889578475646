import React from "react"
import Modal from "react-bootstrap/Modal"
import { useTranslation } from "react-i18next"

export default (props: any) => {
  const { t } = useTranslation()

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.title || "Message"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t(props.message)}</p>
      </Modal.Body>
    </Modal>
  )
}
