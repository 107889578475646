import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import { BsFillPersonFill, BsFillLockFill } from "react-icons/bs"
import { AuthContext } from "@/lib/auth"
import * as api from "@/lib/api"
import { errorHandle } from "@/lib/system"
import { Modal } from "../common"
import FooterLogo from "@/img/footer_logo.png"

export default () => {
  const [verify, setVerify] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [modalShow, setModalShow] = useState<boolean>(false)
  const [user, setUser] = useState<any>(null)
  const authContext = useContext(AuthContext)
  const history = useNavigate()
  const params = useParams<any>()

  useEffect(() => {
    if (params.id) {
      getLoginInfo(params.id)
    }
  }, [])

  const getLoginInfo = async (id: string) => {
    setVerify(true)
    try {
      const json: any = await api.getLoginInfoDb(id)
      if (json.data.user_id && json.data.password) {
        const data = await authContext.signIn(json.data.user_id, json.data.password)
        setUser(data)
      }
    } catch (err) {
      setErrorMessage(errorHandle(err))
    }
    setVerify(false)
  }

  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  useEffect(() => {
    if (authContext.currentUser) {
      window.location.href = "/"
    }
  }, [authContext.currentUser, history, user])

  return (
    <>
      <Modal show={modalShow} message={errorMessage} onHide={() => setModalShow(false)} />
      <SignIn {...{ setUser, setErrorMessage, verify }} />
    </>
  )
}

const SignIn = (props: any) => {
  const authContext = useContext(AuthContext)

  const [username, setUsername] = useState<string>("")
  const [password, setPassword] = useState<string>("")

  const [attempting, setAttempting] = useState<boolean>(false)

  const handleUsernameChange = (e: any) => setUsername(e.target.value)
  const handlePasswordChange = (e: any) => setPassword(e.target.value)

  const handleSignIn = async () => {
    setAttempting(true)
    try {
      const data = await authContext.signIn(username, password)
      setAttempting(false)
      props.setUser(data)
    } catch (err: any) {
      setAttempting(false)
      props.setErrorMessage(errorHandle(err))
    }
  }

  return (
    <>
      <Card className="mx-auto" style={{ boxShadow: "0 5px 15px 0 rgba(0, 0, 0, .5)" }}>
        <Card.Header className="bg-white text-center">
          <div>
            <img src={FooterLogo} className="w-100" />
          </div>
          <h5 className="mt-3">安全衛生点検チェックシート WEB版</h5>
        </Card.Header>

        {props.verify && <Card.Body>認証中...</Card.Body>}
        {!props.verify && (
          <Card.Body className="mt-2">
            <Form>
              <Form.Group>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="input-id">
                    <BsFillPersonFill size={20} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="username"
                    placeholder="ID"
                    autoComplete="username"
                    onChange={handleUsernameChange}
                    disabled={attempting}
                    aria-describedby="input-id"
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="input-pw">
                    <BsFillLockFill size={20} />
                  </InputGroup.Text>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="パスワード"
                    autoComplete="password"
                    onChange={handlePasswordChange}
                    disabled={attempting}
                    aria-describedby="input-pw"
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Button className="btn-primary btn-block w-100 mt-2" onClick={handleSignIn}>
                  <span>送信</span>
                </Button>
              </Form.Group>
            </Form>
          </Card.Body>
        )}
      </Card>
    </>
  )
}
