import * as api from "@/lib/api"
import { IOperator, IOperatorOther, IOption } from "@/lib/interface"
import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import { errorHandle } from "../../lib/system"
import RadioToggle from "../common/radio-toggle"
import { parseOperatorsOthers } from "@/lib/utils"

function App(props: any) {
  const [alertGetBeforeReport, setAlertGetBeforeReport] = useState<boolean>(false)
  const [alertGetBeforeReportMessage, setAlertGetBeforeReportMessage] = useState<string>("")
  const inspectors = props.report.inspector ? props.report.inspector.split(",") : [""]

  useEffect(() => {
    for (let i = 0; i < props.report.operators.length; i++) {
      const operator = props.report.operators[i]
      props.reportDispatch({
        type: "VALID_OPERATOR_ID",
        payload: operator.operator_id,
        index: i,
      })
    }
  }, [props.report.operators])

  useEffect(() => {
    if (props.report.partners && props.report.partners.length === 0) {
      props.reportDispatch({
        type: "PARTNER_ADD",
      })
    }
  }, [props.report.partners, props.reportDispatch])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }

  const handleGetBeforeReport = async (jobCategory: string, jobNumber: string, jobCode1: string, jobCode2: string) => {
    if (!jobCategory || !jobNumber) {
      if (!jobCategory) {
        setAlertGetBeforeReport(true)
        setAlertGetBeforeReportMessage("JOB番号が不正です")
      }
      if (!jobNumber) {
        setAlertGetBeforeReport(true)
        setAlertGetBeforeReportMessage("JOB番号が不正です")
      }
      return // 重要なパラメータが欠けている場合は、ここで処理を終了します
    }
    const beforeReportSearch = {
      job_category: jobCategory,
      job_number: jobNumber,
      job_code1: jobCode1,
      job_code2: jobCode2,
    }
    try {
      const beforeReport = await api.getBeforeReportDb("", "", beforeReportSearch, 0)
      setAlertGetBeforeReport(false)
      props.reportDispatch({
        type: "JOB_DATE",
        payload: beforeReport.data.json.job_date,
      })
      props.reportDispatch({
        type: "WEATHER_ID",
        payload: beforeReport.data.json.weather_id,
      })
      props.reportDispatch({
        type: "TEMPERATURE",
        payload: beforeReport.data.json.temperature,
      })
      props.reportDispatch({
        type: "CONSTRUCTION",
        payload: beforeReport.data.json.construction,
      })
      props.reportDispatch({
        type: "CONTRACT_ID",
        payload: beforeReport.data.json.contract_id,
        list: props.contracts,
      })
      props.reportDispatch({
        type: "CONTRACT_AMOUNT",
        payload: beforeReport.data.json.contract_amount,
      })
      props.reportDispatch({
        type: "CONSTRUCTION_PERIOD_START",
        payload: beforeReport.data.json.construction_period_start,
      })
      props.reportDispatch({
        type: "CONSTRUCTION_PERIOD_END",
        payload: beforeReport.data.json.construction_period_end,
      })
      props.reportDispatch({
        type: "PROGRESS_RATE",
        payload: beforeReport.data.json.progress_rate,
      })
      props.reportDispatch({
        type: "OFFICE_NAME",
        payload: beforeReport.data.json.office_name,
      })
      props.reportDispatch({
        type: "INSPECTOR",
        payload: beforeReport.data.json.inspector,
      })
      props.reportDispatch({
        type: "CONSTRUCTION_ID",
        payload: beforeReport.data.json.construction_id,
      })
      props.reportDispatch({
        type: "CONSTRUCTION_DETAIL_ID",
        payload: beforeReport.data.json.construction_detail_id,
      })
      props.reportDispatch({
        type: "DEPARTMENT_MANAGER",
        payload: beforeReport.data.json.department_manager,
      })
      props.reportDispatch({
        type: "SECTION_MANAGER",
        payload: beforeReport.data.json.section_manager,
      })
      props.reportDispatch({
        type: "PROJECT_MANAGER",
        payload: beforeReport.data.json.project_manager,
      })
      for (const [index, operator] of beforeReport.data.json.operators.entries()) {
        props.reportDispatch({
          type: "OPERATOR_ID",
          payload: operator.operator_id,
          index: index,
        })
        props.reportDispatch({
          type: "VALID_OPERATOR_ID",
          payload: operator.operator_id,
          index: index,
        })
        props.reportDispatch({
          type: "OPERATOR_NUMBER",
          payload: operator.operator_number,
          index: index,
        })
        props.reportDispatch({
          type: "VALID_OPERATOR_NUMBER",
          payload: operator.operator_number,
          index: index,
        })
        if (operator.operator_id === "8855b643-775c-0c5b-0456-42c3d5b799e3") {
          const formattedOperatorOther = parseOperatorsOthers(operator.operator_other)
          props.reportDispatch({
            type: "OPERATOR_OTHER",
            payload: formattedOperatorOther,
            index: index,
          })
          props.reportDispatch({
            type: "VALID_OPERATOR_OTHER",
            payload: formattedOperatorOther,
            index: index,
          })
        }
        if (index !== beforeReport.data.json.operators.length - 1) {
          props.reportDispatch({
            type: "OPERATOR_ADD",
          })
        }
      }

      props.reportDispatch({
        type: "CONSTRUCTION_COMPANY",
        payload: beforeReport.data.json.construction_company,
      })
      for (const [index, partner] of beforeReport.data.json.partners.entries()) {
        props.reportDispatch({
          type: "PARTNER",
          payload: partner,
          index: index,
        })
        if (index !== beforeReport.data.json.partners.length - 1) {
          props.reportDispatch({
            type: "PARTNER_ADD",
          })
        }
      }
      for (const [index, inspection] of beforeReport.data.json.inspections.entries()) {
        props.reportDispatch({
          type: "INSPECTION_ID",
          payload: inspection,
        })
      }
      props.reportDispatch({
        type: "BEFORE_QUESTION_INIT",
        questions: beforeReport.data.json.questions,
      })
    } catch (err) {
      console.error(errorHandle(err))
      setAlertGetBeforeReportMessage("レポートが存在しません")
      setAlertGetBeforeReport(true)
      return
    }
  }
  return (
    <>
      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-1 mb-lg-0">
          <span className="d-print-inline-block">部門</span>
          <span className="alert alert-danger fw-normal small p-1 m-3 d-print-inline">選択必須</span>
        </Col>
        <Col md={12} lg={3}>
          <Form>
            <div className="text-danger">{props.report?.errors?.department_id}</div>
            <Form.Control
              as="select"
              size="sm"
              value={props.report.department_id}
              className={props.report.department_id ? "" : "bg-pink"}
              onChange={(e) =>
                props.reportDispatch({
                  type: "DEPARTMENT_ID",
                  payload: e.currentTarget.value,
                })
              }
              onBlur={(e) =>
                props.reportDispatch({
                  type: "VALID_DEPARTMENT_ID",
                  payload: e.currentTarget.value,
                })
              }
            >
              <option value="">選択してください</option>
              {props.departments?.map((department: IOption, index: number) => {
                return (
                  <option value={department.id} key={`department-${index}`}>
                    {department.name}
                  </option>
                )
              })}
            </Form.Control>
          </Form>
        </Col>
      </Row>
      <hr />

      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          JOB番号
        </Col>
        <Col lg={5} md={12}>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={2}>
                <div className="text-danger">{props.report?.errors?.job_category}</div>
                <Form.Control
                  size="sm"
                  type="text"
                  id="job_category"
                  name="job_category"
                  value={props.report.job_category || ""}
                  placeholder="2桁まで"
                  disabled={props.progress === props.Progress.Attempting}
                  onChange={(e) =>
                    props.reportDispatch({
                      type: "JOB_CATEGORY",
                      payload: e.currentTarget.value,
                    })
                  }
                  onBlur={(e) =>
                    props.reportDispatch({
                      type: "VALID_JOB_CATEGORY",
                      payload: e.currentTarget.value,
                    })
                  }
                  maxLength={2}
                />
              </Col>
              <Col xs={1} className="text-center">
                -
              </Col>
              <Col xs={2}>
                <div className="text-danger">{props.report?.errors?.job_number}</div>
                <Form.Control
                  size="sm"
                  type="text"
                  id="job_number"
                  name="job_number"
                  value={props.report.job_number || ""}
                  placeholder="5桁まで"
                  disabled={props.progress === props.Progress.Attempting}
                  onChange={(e) =>
                    props.reportDispatch({
                      type: "JOB_NUMBER",
                      payload: e.currentTarget.value,
                    })
                  }
                  onBlur={(e) =>
                    props.reportDispatch({
                      type: "VALID_JOB_NUMBER",
                      payload: e.currentTarget.value,
                    })
                  }
                  maxLength={5}
                />
              </Col>
              <Col xs={1} className="text-center">
                -
              </Col>
              <Col xs={2}>
                <div className="text-danger">{props.report?.errors?.job_code1}</div>
                <Form.Control
                  size="sm"
                  type="text"
                  id="job_code1"
                  name="job_code1"
                  value={props.report.job_code1 || ""}
                  placeholder="3桁まで"
                  disabled={props.progress === props.Progress.Attempting}
                  onChange={(e) =>
                    props.reportDispatch({
                      type: "JOB_CODE1",
                      payload: e.currentTarget.value,
                    })
                  }
                  onBlur={(e) =>
                    props.reportDispatch({
                      type: "VALID_JOB_CODE1",
                      payload: e.currentTarget.value,
                    })
                  }
                  maxLength={3}
                />
              </Col>
              <Col xs={1} className="text-center">
                -
              </Col>
              <Col xs={2}>
                <div className="text-danger">{props.report?.errors?.job_code2}</div>
                <Form.Control
                  size="sm"
                  type="text"
                  id="job_code1"
                  name="job_code1"
                  value={props.report.job_code2 || ""}
                  placeholder="3桁まで"
                  disabled={props.progress === props.Progress.Attempting}
                  onChange={(e) =>
                    props.reportDispatch({
                      type: "JOB_CODE2",
                      payload: e.currentTarget.value,
                    })
                  }
                  onBlur={(e) =>
                    props.reportDispatch({
                      type: "VALID_JOB_CODE2",
                      payload: e.currentTarget.value,
                    })
                  }
                  maxLength={3}
                />
              </Col>
            </Row>
            {/* エラーメッセージ */}
            {alertGetBeforeReport && (
              <Row className="mt-3">
                <Col>
                  <div className="alert alert-danger">{alertGetBeforeReportMessage}</div>
                </Col>
              </Row>
            )}
            {/* ボタン */}
            {!props.report.id && (
              <Row className="mt-3">
                <Col>
                  <Button
                    className="btn-sm"
                    disabled={props.progress === props.Progress.Attempting}
                    onClick={() =>
                      handleGetBeforeReport(
                        props.report.job_category,
                        props.report.job_number,
                        props.report.job_code1,
                        props.report.job_code2
                      )
                    }
                  >
                    前回の内容を取得
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          年月日
        </Col>
        <Col md={12} lg={3}>
          <Form>
            <div className="text-danger">{props.report?.errors?.job_date}</div>
            <Form.Control
              size="sm"
              type="datetime-local"
              name="job_date"
              id="job_date"
              value={props.report.job_date}
              placeholder="年月日"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportDispatch({
                  type: "JOB_DATE",
                  payload: e.currentTarget.value,
                })
              }
              onBlur={(e) =>
                props.reportDispatch({
                  type: "VALID_JOB_DATE",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={10}
            />
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          天気
        </Col>
        <Col md={12} lg={10}>
          <Form>
            <div className="text-danger">{props.report?.errors?.weather_id}</div>
            <Row>
              {props.weathers?.map((weather: IOption, index: number) => {
                return (
                  <Col md={2} sm={4} xs={6} key={`weather-${index}`} className="mb-2 mb-md-0">
                    <RadioToggle
                      id={`weather-${index}`}
                      name="weather"
                      label={weather.name}
                      value={weather.id}
                      checked={props.report.weather_id === weather.id}
                      onChange={(e: any) =>
                        props.reportDispatch({
                          type: "WEATHER_ID",
                          payload: e.currentTarget.value,
                        })
                      }
                      onBlur={(e: any) =>
                        props.reportDispatch({
                          type: "VALID_WEATHER_ID",
                          payload: e.currentTarget.value,
                        })
                      }
                    />
                  </Col>
                )
              })}
            </Row>
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          気温
        </Col>
        <Col md={6} lg={3}>
          <Form onSubmit={handleSubmit}>
            <div className="text-danger">{props.report?.errors?.temperature}</div>
            <Form.Control
              size="sm"
              type="text"
              id="temperature"
              name="temperature"
              value={props.report.temperature}
              placeholder="気温"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportDispatch({
                  type: "TEMPERATURE",
                  payload: e.currentTarget.value,
                })
              }
              onBlur={(e) =>
                props.reportDispatch({
                  type: "VALID_TEMPERATURE",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={3}
            />
          </Form>
        </Col>
        <Col xs={2} lg={3}>
          °C
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          工事名
        </Col>
        <Col md={6} lg={3}>
          <Form onSubmit={handleSubmit}>
            <div className="text-danger">{props.report?.errors?.construction}</div>
            <Form.Control
              size="sm"
              type="text"
              id="construction"
              name="construction"
              value={props.report.construction}
              placeholder="工事名"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportDispatch({
                  type: "CONSTRUCTION",
                  payload: e.currentTarget.value,
                })
              }
              onBlur={(e) =>
                props.reportDispatch({
                  type: "VALID_CONSTRUCTION",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={40}
            />
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          請負区分
        </Col>
        <Col md={12} lg={10}>
          <Form>
            <div className="text-danger">{props.report?.errors?.contract_id}</div>
            <Row>
              {props.contracts?.map((contract: IOption, index: number) => {
                return (
                  <Col md={2} sm={4} xs={6} key={`contract-${index}`} className="mb-2 mb-md-0">
                    <RadioToggle
                      id={`contract-${index}`}
                      name="contract"
                      label={contract.name}
                      value={contract.id}
                      checked={props.report.contract_id === contract.id}
                      onChange={(e: any) =>
                        props.reportDispatch({
                          type: "CONTRACT_ID",
                          payload: e.currentTarget.value,
                          list: props.contracts,
                        })
                      }
                      onBlur={(e: any) =>
                        props.reportDispatch({
                          type: "VALID_CONTRACT_ID",
                          payload: e.currentTarget.value,
                        })
                      }
                    />
                  </Col>
                )
              })}
            </Row>
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          請負金額
        </Col>
        <Col xs={5} lg={3}>
          <Form onSubmit={handleSubmit}>
            <div className="text-danger">{props.report?.errors?.contract_amount}</div>
            <Form.Control
              size="sm"
              type="text"
              id="contract_amount"
              name="contract_amount"
              value={props.report.contract_amount}
              placeholder="請負金額"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportDispatch({
                  type: "CONTRACT_AMOUNT",
                  payload: e.currentTarget.value,
                })
              }
              onBlur={(e) =>
                props.reportDispatch({
                  type: "VALID_CONTRACT_AMOUNT",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={11}
            />
          </Form>
        </Col>
        <Col xs={2} lg={3}>
          百万円
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          工期
        </Col>
        <Col lg={5} md={12}>
          <Form onSubmit={handleSubmit}>
            <div className="text-danger">{props.report?.errors?.construction_period_start}</div>
            <Row>
              <Col xs={4} md={3}>
                <Form.Control
                  size="sm"
                  type="date"
                  name="construction_period_start"
                  id="construction_period_start"
                  value={props.report.construction_period_start}
                  placeholder="工期開始日"
                  disabled={props.progress === props.Progress.Attempting}
                  onChange={(e) =>
                    props.reportDispatch({
                      type: "CONSTRUCTION_PERIOD_START",
                      payload: e.currentTarget.value,
                    })
                  }
                  onBlur={(e) =>
                    props.reportDispatch({
                      type: "VALID_CONSTRUCTION_PERIOD_START",
                      payload: e.currentTarget.value,
                    })
                  }
                  maxLength={10}
                />
              </Col>
              <Col xs={1} md={2} className="text-center">
                -
              </Col>
              <Col xs={4} md={3}>
                <div className="text-danger">{props.report?.errors?.construction_period_end}</div>
                <Form.Control
                  size="sm"
                  type="date"
                  name="construction_period_end"
                  id="construction_period_end"
                  value={props.report.construction_period_end}
                  placeholder="工期完了日"
                  disabled={props.progress === props.Progress.Attempting}
                  onChange={(e) =>
                    props.reportDispatch({
                      type: "CONSTRUCTION_PERIOD_END",
                      payload: e.currentTarget.value,
                    })
                  }
                  onBlur={(e) =>
                    props.reportDispatch({
                      type: "VALID_CONSTRUCTION_PERIOD_END",
                      payload: e.currentTarget.value,
                    })
                  }
                  maxLength={10}
                />
              </Col>
              <Col xs={2} md={2}>
                <div className="text-danger">{props.report?.errors?.progress_rate}</div>
                <Form.Control
                  size="sm"
                  type="number"
                  id="progress_rate"
                  name="progress_rate"
                  value={props.report.progress_rate}
                  placeholder="進捗率"
                  disabled={props.progress === props.Progress.Attempting}
                  onChange={(e) =>
                    props.reportDispatch({
                      type: "PROGRESS_RATE",
                      payload: e.currentTarget.value,
                    })
                  }
                  onBlur={(e) =>
                    props.reportDispatch({
                      type: "VALID_PROGRESS_RATE",
                      payload: e.currentTarget.value,
                    })
                  }
                  min={0}
                  max={100}
                />
              </Col>
              <Col xs={1} md={1}>
                %
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          部署
        </Col>
        <Col md={12} lg={3}>
          <Form onSubmit={handleSubmit}>
            <div className="text-danger">{props.report?.errors?.office_name}</div>
            <Form.Control
              size="sm"
              type="text"
              name="office_name"
              id="office_name"
              value={props.report.office_name}
              placeholder="部署"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportDispatch({
                  type: "OFFICE_NAME",
                  payload: e.currentTarget.value,
                })
              }
              onBlur={(e) =>
                props.reportDispatch({
                  type: "VALID_OFFICE_NAME",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={15}
            />
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          工事種類
        </Col>
        <Col md={12} lg={8}>
          <Form>
            <div className="text-danger">{props.report?.errors?.construction_id}</div>
            <Row className="mx-auto">
              <div className="mb-1 px-0">選択1：</div>

              {props.constructions?.map((construction: IOption, index: number) => {
                return (
                  <Col md={2} sm={4} xs={6} key={`construction-${index}`} className="mb-2 mb-md-0 ps-0">
                    <RadioToggle
                      id={`construction-${index}`}
                      name="construction_id"
                      label={construction.name}
                      value={construction.id}
                      checked={props.report.construction_id === construction.id}
                      onChange={(e: any) =>
                        props.reportDispatch({
                          type: "CONSTRUCTION_ID",
                          payload: e.currentTarget.value,
                        })
                      }
                      onBlur={(e: any) =>
                        props.reportDispatch({
                          type: "VALID_CONSTRUCTION_ID",
                          payload: e.currentTarget.value,
                        })
                      }
                    />
                  </Col>
                )
              })}
            </Row>
            <div className="text-danger">{props.report?.errors?.construction_deatil_id}</div>
            <Row className="mt-3 align-items-center mx-auto">
              <div className="mb-1 px-0">選択2：</div>
              <br />
              {props.constructionDetails?.map((constructionDetail: IOption, index: number) => {
                return (
                  <Col md={2} sm={4} xs={6} key={`construction-detail-${index}`} className="mb-2 mb-md-0 ps-0">
                    <RadioToggle
                      id={`construction-detail-${index}`}
                      name="construction_detail"
                      label={constructionDetail.name}
                      value={constructionDetail.id}
                      checked={props.report.construction_detail_id === constructionDetail.id}
                      onChange={(e: any) =>
                        props.reportDispatch({
                          type: "CONSTRUCTION_DETAIL_ID",
                          payload: e.currentTarget.value,
                        })
                      }
                      onBlur={(e: any) =>
                        props.reportDispatch({
                          type: "VALID_CONSTRUCTION_DETAIL_ID",
                          payload: e.currentTarget.value,
                        })
                      }
                    />
                  </Col>
                )
              })}
            </Row>
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          点検者
        </Col>
        <Col md={12} lg={3} className="mb-2">
          <Form onSubmit={handleSubmit}>
            <div className="text-danger">{props.report?.errors?.department_manager}</div>
            <Form.Control
              size="sm"
              type="text"
              name="department_manager"
              id="department_manager"
              value={props.report.department_manager}
              placeholder="部長"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportDispatch({
                  type: "DEPARTMENT_MANAGER",
                  payload: e.currentTarget.value,
                })
              }
              onBlur={(e) =>
                props.reportDispatch({
                  type: "VALID_DEPARTMENT_MANAGER",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={50}
            />
          </Form>
        </Col>
        <Col md={12} lg={3} className="mb-2">
          <Form onSubmit={handleSubmit}>
            <div className="text-danger">{props.report?.errors?.section_manager}</div>
            <Form.Control
              size="sm"
              type="text"
              name="section_manager"
              id="section_manager"
              value={props.report.section_manager}
              placeholder="課長"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportDispatch({
                  type: "SECTION_MANAGER",
                  payload: e.currentTarget.value,
                })
              }
              onBlur={(e) =>
                props.reportDispatch({
                  type: "VALID_SECTION_MANAGER",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={50}
            />
          </Form>
        </Col>
        <Col md={4} lg={3}>
          <Form onSubmit={handleSubmit}>
            <div className="text-danger">{props.report?.errors?.inspector}</div>
            {inspectors.map((inspector: string, index: number) => {
              return (
                <Row key={`inspector-${index}`} className="mb-2 mx-0">
                  <Col xs={6} className="px-0">
                    <div className="text-danger">{props.report?.errors?.inspector?.[index]?.idMessage}</div>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="inspector"
                      id="inspector"
                      value={inspector}
                      placeholder="点検者"
                      disabled={props.progress === props.Progress.Attempting}
                      onChange={(e) => {
                        const payloadWithoutSpecialChars = e.currentTarget.value.replace(/[,:;"']/g, "")
                        props.reportDispatch({
                          type: "INSPECTOR",
                          payload: payloadWithoutSpecialChars,
                          index: index,
                        })
                      }}
                      onBlur={(e) =>
                        props.reportDispatch({
                          type: "VALID_INSPECTOR",
                          payload: e.currentTarget.value,
                        })
                      }
                      maxLength={50}
                    />
                  </Col>
                  <Col xs={2}>
                    {inspectors.length > 1 && (
                      <Button
                        size="sm"
                        className="text-right btn-danger"
                        disabled={props.progress === props.Progress.Attempting}
                        onClick={() =>
                          props.reportDispatch({
                            type: "INSPECTOR_DEL",
                            index: index,
                          })
                        }
                      >
                        削除
                      </Button>
                    )}
                  </Col>
                </Row>
              )
            })}
            {!(props.report?.inspectors?.length >= 12) && (
              <Button
                size="sm"
                className="text-right"
                disabled={props.progress === props.Progress.Attempting}
                onClick={() =>
                  props.reportDispatch({
                    type: "INSPECTOR_ADD",
                  })
                }
              >
                追加
              </Button>
            )}
            <span className="fst-italic d-block"> , : ; &quot; &apos;は入力できません</span>
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          被点検者
        </Col>
        <Col md={12} lg={3}>
          <Form onSubmit={handleSubmit}>
            <div className="text-danger">{props.report?.errors?.project_manager}</div>
            <Form.Control
              size="sm"
              type="text"
              name="project_manager"
              id="project_manager"
              value={props.report.project_manager}
              placeholder="現場代理人"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportDispatch({
                  type: "PROJECT_MANAGER",
                  payload: e.currentTarget.value,
                })
              }
              onBlur={(e) =>
                props.reportDispatch({
                  type: "VALID_PROJECT_MANAGER",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={50}
            />
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          当日の作業員人数
        </Col>
        <Col xl={6}>
          <Form onSubmit={handleSubmit}>
            {props.report.operators?.map((operator: IOperator, index: number) => {
              return (
                <Row key={`operators-${index}`} className="mb-2 mx-0">
                  <Col xs={6} className="px-0">
                    <div className="text-danger">{props.report?.errors?.operators?.[index]?.idMessage}</div>
                    <Form.Control
                      as="select"
                      size="sm"
                      value={operator.operator_id}
                      onChange={(e) =>
                        props.reportDispatch({
                          type: "OPERATOR_ID",
                          payload: e.currentTarget.value,
                          index: index,
                        })
                      }
                      onBlur={(e) =>
                        props.reportDispatch({
                          type: "VALID_OPERATOR_ID",
                          payload: e.currentTarget.value,
                          index: index,
                        })
                      }
                      className="mb-2"
                    >
                      <option value="">選択してください</option>
                      {props.operators?.map((operatorOption: IOption, operatorIndex: number) => {
                        return (
                          <option
                            value={operatorOption.id}
                            key={`operator-${operatorIndex}`}
                            disabled={props.report?.operators?.some(
                              (operator: IOperator) => operator.operator_id === operatorOption.id
                            )}
                          >
                            {operatorOption.name}
                          </option>
                        )
                      })}
                    </Form.Control>
                    {/* その他だけ表示 */}
                    {props.report?.operators[index]?.operator_id === "8855b643-775c-0c5b-0456-42c3d5b799e3" && (
                      <>
                        {Array.isArray(props.report?.operators[index]?.operator_other) &&
                          props.report?.operators[index]?.operator_other.map(
                            (other: IOperatorOther, otherIndex: number) => {
                              return (
                                <>
                                  <Row key={`operator-other-${otherIndex}`} className="mb-2 mx-0">
                                    <Col xs={5} className="ms-1 px-0">
                                      <Form.Control
                                        size="sm"
                                        type="text"
                                        name="operator_other"
                                        id="operator_other"
                                        value={other.other_name === undefined ? undefined : other.other_name}
                                        placeholder="記入欄"
                                        disabled={props.progress === props.Progress.Attempting}
                                        onChange={(e) =>
                                          props.reportDispatch({
                                            type: "OPERATOR_OTHER_NAME",
                                            payload: e.currentTarget.value,
                                            index: otherIndex,
                                          })
                                        }
                                        onBlur={(e) =>
                                          props.reportDispatch({
                                            type: "VALID_OPERATOR_OTHER_NAME",
                                            payload: e.currentTarget.value,
                                            index: index,
                                            otherIndex: otherIndex,
                                          })
                                        }
                                        maxLength={20}
                                      />
                                    </Col>
                                    <Col xs={3}>
                                      <Form.Control
                                        size="sm"
                                        type="number"
                                        name="operator_number"
                                        id="operator_number"
                                        value={other.other_number === null ? undefined : other.other_number}
                                        placeholder="人数"
                                        disabled={props.progress === props.Progress.Attempting}
                                        onChange={(e) =>
                                          props.reportDispatch({
                                            type: "OPERATOR_OTHER_NUMBER",
                                            payload: e.currentTarget.value,
                                            index: otherIndex,
                                          })
                                        }
                                        onBlur={(e) =>
                                          props.reportDispatch({
                                            type: "VALID_OPERATOR_OTHER_NUMBER",
                                            payload: e.currentTarget.value,
                                            index: index,
                                            otherIndex: otherIndex,
                                          })
                                        }
                                        maxLength={10}
                                        min={0}
                                      />
                                    </Col>
                                    <Col xs={1} lg={1}>
                                      人
                                    </Col>
                                    <Col xs={2} className="pe-0">
                                      {props.report?.operators[index]?.operator_other?.length > 1 && (
                                        <Button
                                          size="sm"
                                          className="text-right btn-danger"
                                          disabled={props.progress === props.Progress.Attempting}
                                          onClick={() =>
                                            props.reportDispatch({
                                              type: "OPERATOR_OTHER_DEL",
                                              index: index,
                                              otherIndex: otherIndex,
                                            })
                                          }
                                        >
                                          削除
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                </>
                              )
                            }
                          )}
                        {!(props.report?.operators[index]?.operator_other.length >= 14) && (
                          <Button
                            size="sm"
                            className="text-right"
                            disabled={props.progress === props.Progress.Attempting}
                            onClick={() =>
                              props.reportDispatch({
                                type: "OPERATOR_OTHER_ADD",
                              })
                            }
                          >
                            その他追加
                          </Button>
                        )}
                      </>
                    )}
                  </Col>
                  {/* その他以外表示 */}
                  {props.report?.operators[index]?.operator_id !== "8855b643-775c-0c5b-0456-42c3d5b799e3" && (
                    <>
                      <Col xs={3}>
                        <div className="text-danger">{props.report?.errors?.operators?.[index]?.numMessage}</div>
                        <Form.Control
                          size="sm"
                          type="number"
                          name="operator_number"
                          id="operator_number"
                          value={operator.operator_number === null ? undefined : operator.operator_number}
                          placeholder="人数"
                          disabled={props.progress === props.Progress.Attempting}
                          onChange={(e) =>
                            props.reportDispatch({
                              type: "OPERATOR_NUMBER",
                              payload: e.currentTarget.value,
                              index: index,
                            })
                          }
                          onBlur={(e) =>
                            props.reportDispatch({
                              type: "VALID_OPERATOR_NUMBER",
                              payload: e.currentTarget.value,
                              index: index,
                            })
                          }
                          maxLength={10}
                          min={0}
                        />
                      </Col>
                      <Col xs={1} lg={1}>
                        人
                      </Col>
                    </>
                  )}
                  <Col xs={2}>
                    {props.report?.operators?.length > 1 && (
                      <Button
                        size="sm"
                        className="text-right btn-danger"
                        disabled={props.progress === props.Progress.Attempting}
                        onClick={() =>
                          props.reportDispatch({
                            type: "OPERATOR_DEL",
                            index: index,
                          })
                        }
                      >
                        削除
                      </Button>
                    )}
                  </Col>
                </Row>
              )
            })}
            {!(props.report?.operators?.length >= 14) && (
              <Button
                size="sm"
                className="text-right"
                disabled={props.progress === props.Progress.Attempting}
                onClick={() =>
                  props.reportDispatch({
                    type: "OPERATOR_ADD",
                  })
                }
              >
                追加
              </Button>
            )}
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          建築会社（統括安全衛生責任者）
        </Col>
        <Col md={12} lg={8}>
          <Form onSubmit={handleSubmit}>
            <div className="text-danger">{props.report?.errors?.construction_company}</div>
            <Form.Control
              size="sm"
              type="text"
              name="construction_company"
              id="construction_company"
              value={props.report.construction_company}
              placeholder="建築会社（統括安全衛生責任者）"
              disabled={props.progress === props.Progress.Attempting}
              onChange={(e) =>
                props.reportDispatch({
                  type: "CONSTRUCTION_COMPANY",
                  payload: e.currentTarget.value,
                })
              }
              onBlur={(e) =>
                props.reportDispatch({
                  type: "VALID_CONSTRUCTION_COMPANY",
                  payload: e.currentTarget.value,
                })
              }
              maxLength={15}
            />
          </Form>
        </Col>
      </Row>
      <hr />
      <Row className="align-items-center">
        <Col md={12} lg={2} className="fw-bold mb-2 mb-lg-0">
          当日入場協力会社
        </Col>
        <Col md={12} lg={6}>
          <Form onSubmit={handleSubmit}>
            {props.report.partners?.map((partner: string, index: number) => {
              return (
                <Row key={`partners-${index}`} className="mb-2">
                  <Col xs={6}>
                    <div className="text-danger">{props.report?.errors?.partners?.[index]}</div>
                    <Form.Control
                      size="sm"
                      type="text"
                      name={`partners-${index}`}
                      id={`partners-${index}`}
                      value={partner}
                      placeholder="当日入場協力会社"
                      disabled={props.progress === props.Progress.Attempting}
                      onChange={(e) =>
                        props.reportDispatch({
                          type: "PARTNER",
                          payload: e.currentTarget.value,
                          index: index,
                        })
                      }
                      onBlur={(e) =>
                        props.reportDispatch({
                          type: "VALID_PARTNER",
                          payload: e.currentTarget.value,
                          index: index,
                        })
                      }
                      maxLength={30}
                    />
                  </Col>
                  <Col xs={6}>
                    {props.report?.partners?.length > 1 && (
                      <Button
                        size="sm"
                        className="text-right btn-danger"
                        disabled={props.progress === props.Progress.Attempting}
                        onClick={() =>
                          props.reportDispatch({
                            type: "PARTNER_DEL",
                            index: index,
                          })
                        }
                      >
                        削除
                      </Button>
                    )}
                  </Col>
                </Row>
              )
            })}
            <Button
              size="sm"
              className="text-right"
              disabled={props.progress === props.Progress.Attempting}
              onClick={() =>
                props.reportDispatch({
                  type: "PARTNER_ADD",
                })
              }
            >
              追加
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default App
