import * as api from "@/lib/api"
import {
  IOption,
  IReport,
  IReportQuestion,
  IReportComments,
  DraftReport,
  isReport,
  OldImages,
  IOperatorOther,
  IOperator,
  isOperatorOther,
} from "@/lib/interface"
import { errorHandle } from "@/lib/system"
import { parseComments, parseOperatorsOthers, removeQueryString } from "@/lib/utils"
import React, { useEffect, useReducer, useState, useRef } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { Link, useNavigate, useParams, useLocation } from "react-router-dom"
import FormMain from "./_form-main"
import FormQuestion from "./_form-question"
import { ACTIONTYPE, errorCounter, initialReport, reducerFunc } from "./form-func"
enum Progress {
  Preparing = 0,
  Attempting = 1,
}

function App() {
  const params = useParams<any>()
  const [progress, setProgress] = useState(Progress.Preparing)
  const [alertInfo, setAlertInfo] = useState<boolean>(false)
  const [alertDanger, setAlertDanger] = useState<boolean>(false)
  const [draftComplete, setDraftComplete] = useState<boolean>(false)
  const [weathers, setWeathers] = useState<IOption[]>()
  const [contracts, setContracts] = useState<IOption[]>()
  const [departments, setDepartments] = useState<IOption[]>()
  const [constructions, setConstructions] = useState<IOption[]>()
  const [constructionDetails, setConstructionDetails] = useState<IOption[]>()
  const [operators, setOperators] = useState<IOption[]>()
  const [inspections, setInspections] = useState<IOption[]>()
  const [allQuestions, setAllQuestions] = useState<IOption[]>()
  const [report, reportDispatch] = useReducer<React.Reducer<IReport, ACTIONTYPE>>(reducerFunc, initialReport)
  const [errorCount, setErrorCount] = useState(0)
  const [draftId, setDraftId] = useState<string>("")
  const [draftdata, setDraftReport] = useState<DraftReport>(initialReport)
  const history = useNavigate()
  const { state } = useLocation()

  //古い画像のpathを保持する
  const oldImages = useRef<OldImages[]>([])

  //下書きの取得
  const fetchDraft = async (draftId: string) => {
    const draftJson: any = await api.getDraftDb(draftId)
    const draftdata = draftJson?.data[0].report
    if (isReport(draftdata)) {
      const formattedOperator: IOperator[] = (draftdata?.operators || []).map((operator: IOperator) => {
        return {
          ...operator,
          operator_other: parseOperatorsOthers(operator.operator_other),
        }
      })
      const updateDraftData = { ...draftdata, operators: formattedOperator }
      setDraftReport(updateDraftData)
      reportDispatch({
        type: "SET_DRAFT_REPORT",
        payload: updateDraftData,
      })
    }
  }

  useEffect(() => {
    getOptions()
    // stateにdraftIdがある場合は下書きを取得
    if (state?.draftId !== undefined && state !== null) {
      setDraftId(state.draftId)
      fetchDraft(state.draftId)
    } else {
      getReport()
    }
  }, [history])

  useEffect(() => {
    setTimeout(() => setAlertInfo(false), 1000)
  }, [alertInfo])

  useEffect(() => {
    setTimeout(() => setAlertDanger(false), 1000)
  }, [alertDanger])

  useEffect(() => {
    setTimeout(() => setDraftComplete(false), 1000)
  }, [draftComplete])

  const timerRef = useRef<number | null>(null)

  // 自動保存機能
  useEffect(() => {
    console.log("report", report)
    console.log("自動保存機能を有効化")
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    const draftSave = async () => {
      if (errorCount === 0) {
        console.log("自動保存")
        if (await compareDraftAndReport(initialReport, report)) {
          console.log("初期値と同じため中止")
          return
        }
        //ファイルアップロード
        if (await compareDraftAndReport(draftdata, report)) {
          console.log("自動保存中止")
          return
        }
        console.log("自動保存を実行")
        const saveQuestions = await Promise.all(
          report.questions?.map(async (question: IReportQuestion, index: number) => {
            if (question.image_file?.name && !question.image) {
              const filename = await fileUpload(question.image_file)
              return { ...question, image: filename }
            }
            if (draftdata.questions !== undefined) {
              if (
                question.image_file?.name &&
                draftdata.questions[index].image_file?.name !== question.image_file?.name
              ) {
                const filename = await fileUpload(question.image_file)
                return { ...question, image: filename }
              }
            }
            return question
          }) || []
        )
        const getDraftId = await api.setDraftDb({ ...report, questions: saveQuestions, draftId: draftId })
        setDraftId(getDraftId.data.draftId)
        setDraftReport({ ...report, questions: saveQuestions })
        if (saveQuestions) {
          await reportDispatch({
            type: "QUESTION_INIT",
            questions: saveQuestions,
          })
        }
        setDraftComplete(true)
        console.log("自動保存完了")
      } else {
        console.error("エラーを検知したため中止")
      }
    }

    timerRef.current = setTimeout(async () => {
      await draftSave()
    }, 3000) as unknown as number

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [report])

  //選択肢の取得と初期値設定
  const getOptions = async () => {
    try {
      const weatherJson: any = await api.getWeathersDb()
      if (weatherJson?.data) {
        setWeathers(weatherJson?.data.json)
      }
      const contractJson: any = await api.getContractsDb()
      if (contractJson?.data) {
        setContracts(contractJson?.data.json)
      }
      const departmentJson: any = await api.getDepartmentsDb()
      if (departmentJson?.data) {
        setDepartments(departmentJson?.data.json)
      }
      const constructionJson: any = await api.getConstructionsDb()
      if (constructionJson?.data) {
        setConstructions(constructionJson?.data.json)
      }
      const constructionDetailJson: any = await api.getConstructionDetailsDb()
      if (constructionDetailJson?.data) {
        setConstructionDetails(constructionDetailJson?.data.json)
      }
      const operatorJson: any = await api.getOperatorsDb()
      if (operatorJson?.data) {
        setOperators(operatorJson?.data.json)
      }
      const inspectionJson: any = await api.getInspectionsDb()
      if (inspectionJson?.data) {
        //選択肢用
        setInspections(inspectionJson?.data.json)
        //レポート用にあらかじめチェックする
        reportDispatch({
          type: "INSPECTION_INIT",
          list: inspectionJson?.data.json,
        })
      }
      const questionJson: any = await api.getQuestionsDb()
      if (questionJson?.data) {
        //チェックリスト全体取得
        setAllQuestions(questionJson?.data.json)
        //チェックリストのデフォルト表示制御
        reportDispatch({
          type: "QUESTION_INIT",
          questions: questionJson?.data.json,
        })
      }
    } catch (err) {
      console.log(errorHandle(err))
    }
  }

  const getReport = async () => {
    try {
      if (params.id) {
        const reportJson = await api.getReportDb(params.id)
        const apiReportData = reportJson?.data?.json
        // comment文字列を配列に変換
        if (apiReportData && apiReportData.comment !== undefined && apiReportData.request_comment !== undefined) {
          apiReportData.comment = parseComments(apiReportData.comment)
          apiReportData.request_comment = parseComments(apiReportData.request_comment)
        }
        // operator_other文字列を配列に変換
        if (apiReportData && apiReportData.operators) {
          apiReportData.operators = apiReportData.operators.map((operator: IOperator) => {
            return {
              ...operator,
              operator_other: parseOperatorsOthers(operator.operator_other),
            }
          })
        }
        if (isReport(apiReportData)) {
          if (apiReportData && apiReportData.questions) {
            oldImages.current = apiReportData.questions
              .filter(
                (question): question is { id: string; image: string } =>
                  question.id !== undefined && question.image !== undefined && question.image !== null
              ) // undefinedでない要素のみをフィルタリング
              .map((question) => ({
                questionId: question.id, // この時点で question.id と question.image は undefined ではない
                imagePath: question.image,
              }))
          }
          setDraftReport(apiReportData)
          reportDispatch({
            type: "SET_REPORT",
            payload: apiReportData,
          })
        }
      } else {
        const reportData = initialReport
        reportDispatch({
          type: "SET_REPORT",
          payload: reportData,
        })
      }
    } catch (err) {
      console.log(errorHandle(err))
      history(`/report/create`)
    }
  }
  //点検分類と部門が変更された時
  useEffect(() => {
    if (allQuestions) {
      reportDispatch({
        type: "QUESTION_INIT",
        questions: allQuestions,
      })
    }
  }, [report.inspections, report.department_id])
  //エラーカウント
  useEffect(() => {
    console.log("errorCount")
    setErrorCount(errorCounter(report))
  }, [report])

  const handleSave = async () => {
    setProgress(Progress.Attempting)
    if (errorCount === 0) {
      try {
        //ファイルアップロード
        const saveQuestions = await Promise.all(
          report.questions?.map(async (question: IReportQuestion, index: number) => {
            if (question.image_file?.name && !question.image) {
              const filename = await fileUpload(question.image_file)
              return { ...question, image: filename }
            }
            if (draftdata.questions !== undefined) {
              if (
                question.image_file?.name &&
                draftdata.questions[index].image_file?.name !== question.image_file?.name
              ) {
                const filename = await fileUpload(question.image_file)
                return { ...question, image: filename }
              }
            }
            return question
          }) || []
        )
        // comment配列を文字列に変換
        report.comment = formatComments(report.comment)
        report.request_comment = formatComments(report.request_comment)
        report.operators = report?.operators?.map((operator) => {
          if (isOperatorOther(operator.operator_other)) {
            const formatedOperatorNumber = operator.operator_number === null ? 0 : operator.operator_number
            const formatedOperatorOther = formatOperatorOther(operator.operator_other)
            return { ...operator, operator_number: formatedOperatorNumber, operator_other: formatedOperatorOther }
          }
          return operator
        })
        console.log("save", report)
        const json = await api.setReportDb({ ...report, questions: saveQuestions })
        //新規登録->更新への表示切り替えのため、IDをreportにセットする
        if (json.data.reportId) {
          reportDispatch({
            type: "REPORT_ID",
            payload: json.data.reportId,
          })
        }
        setAlertInfo(true)
        if (draftId) {
          await api.delDraftDb(draftId)
        }
        console.log("report save")
        history(`/report/${json.data.reportId}/edit`)
      } catch (err) {
        setAlertDanger(true)
        console.log(errorHandle(err))
      } finally {
        setProgress(Progress.Preparing)
      }
    }
  }

  const handleUpdate = async () => {
    setProgress(Progress.Attempting)
    if (errorCount === 0) {
      try {
        //ファイルアップロード
        const saveQuestions = await Promise.all(
          report.questions?.map(async (question: IReportQuestion, index: number) => {
            if (question.image_file?.name && !question.image) {
              const filename = await fileUpload(question.image_file)
              return { ...question, image: filename }
            }
            if (draftdata.questions !== undefined) {
              if (
                question.image_file?.name &&
                draftdata.questions[index].image_file?.name !== question.image_file?.name
              ) {
                const filename = await fileUpload(question.image_file)
                return { ...question, image: filename }
              }
            }
            return question
          }) || []
        )
        //古い画像の削除
        const imagesToDeleteList = saveQuestions.reduce<string[]>((acc, question) => {
          const oldImage = oldImages.current.find((oldImage) => oldImage.questionId === question.id)
          if (oldImage && question.image !== oldImage.imagePath) {
            acc.push(removeQueryString(oldImage.imagePath))
          }
          return acc
        }, [])
        console.log("imagesToDeleteList", imagesToDeleteList)
        //del-image APIを叩く
        imagesToDeleteList.forEach(async (imagePath) => {
          try {
            await api.delImage(imagePath)
            console.log("image deleted", imagePath)
          } catch (err) {
            console.log(errorHandle(err))
          }
        })
        //古い画像のpathを更新したものに更新する
        oldImages.current = saveQuestions
          .filter(
            (question): question is { id: string; image: string } =>
              question.id !== undefined && question.image !== undefined && question.image !== null
          ) // undefinedでない要素のみをフィルタリング
          .map((question) => ({
            questionId: question.id, // この時点で question.id と question.image は undefined ではない
            imagePath: question.image,
          }))
        // comment配列を文字列に変換
        report.comment = formatComments(report.comment)
        report.request_comment = formatComments(report.request_comment)
        report.operators = report?.operators?.map((operator) => {
          if (isOperatorOther(operator.operator_other)) {
            const formatedOperatorNumber = operator.operator_number === null ? 0 : operator.operator_number
            const formatedOperatorOther = formatOperatorOther(operator.operator_other)
            return { ...operator, operator_number: formatedOperatorNumber, operator_other: formatedOperatorOther }
          }
          return operator
        })

        console.log("update", report)
        await api.updateReportDb({ ...report, questions: saveQuestions })
        setAlertInfo(true)
        //保存されたので下書きを削除
        if (draftId) {
          await api.delDraftDb(draftId)
        }
        console.log("report update")
        getReport()
      } catch (err) {
        setAlertDanger(true)
        console.log(errorHandle(err))
      } finally {
        setProgress(Progress.Preparing)
      }
    }
  }

  //アップロード進捗率をログ出力
  const thumbnailOptions = {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      const { loaded, total } = progressEvent
      const percent = Math.floor((loaded * 100) / total)
      console.log(`${loaded}kb of ${total}kb : ${percent}%`)
    },
  }

  //ファイルのアップロード処理
  const fileUpload = async (file: File) => {
    const ext = file.name.split(".").pop()
    let retrunFilename = ""
    if (file.type && ext) {
      // S3の認証済みアップロードURLを取得する サムネイルと動画それぞれ別のアップロードURLとなる
      const put_json = await api.getImageUploadUrl(file.type, ext)
      if (put_json) {
        const url = put_json?.data
        await api.putFile(url, file, thumbnailOptions)
        const tempUrl = url.split("?Content-Type=")?.[0]
        retrunFilename = `question_images/${tempUrl.split("/question_images/")?.slice(-1)?.[0]}`
      }
    }
    console.log(retrunFilename)
    return retrunFilename
  }

  return (
    <>
      {alertInfo && (
        <div className="alert alert-info position-fixed top-50 start-50 translate-middle p-5 z-index-10">
          保存しました
        </div>
      )}
      {alertDanger && (
        <div className="alert alert-danger position-fixed top-50 start-50 translate-middle p-5 z-index-10">
          保存できません
        </div>
      )}
      {draftComplete && (
        <div className="alert alert-info position-fixed m-3 p-3 z-index-10" style={{ bottom: "100px", right: "20px" }}>
          下書きが保存されました
        </div>
      )}
      <h1 className="h4">安全パトロール用紙登録</h1>
      <hr />
      <FormMain
        reportDispatch={reportDispatch}
        report={report}
        weathers={weathers}
        contracts={contracts}
        departments={departments}
        constructions={constructions}
        constructionDetails={constructionDetails}
        operators={operators}
        setOperators={setOperators}
        Progress={Progress}
        progress={progress}
        setProgress={setProgress}
      />

      <FormQuestion
        reportDispatch={reportDispatch}
        report={report}
        inspections={inspections}
        allQuestions={allQuestions}
        Progress={Progress}
        progress={progress}
        setProgress={setProgress}
        oldImages={oldImages.current}
      />
      <Row className="fixed-bottom text-center bg-light p-4">
        {errorCount > 0 && <div className="alert alert-danger">エラー：{errorCount}項目</div>}
        <Col xs={6}>
          <Link to={`/`} className="text-right btn btn-secondary btn-sm w-50">
            一覧へ戻る
          </Link>
        </Col>
        {errorCount === 0 && !report.id && (
          <Col xs={6}>
            <Button
              size="sm"
              className="text-right w-50"
              disabled={progress === Progress.Attempting}
              onClick={handleSave}
            >
              保存
            </Button>
          </Col>
        )}
        {errorCount === 0 && report.id && (
          <Col xs={6}>
            <Button
              size="sm"
              className="text-right btn-warning w-50"
              disabled={progress === Progress.Attempting}
              onClick={handleUpdate}
            >
              更新
            </Button>
          </Col>
        )}
      </Row>
    </>
  )
}

function formatComments(comments: string | IReportComments[] | undefined) {
  return Array.isArray(comments)
    ? comments.map((obj) => `author: ${obj.author}, comment: ${obj.comment}`).join("; ")
    : comments
}

function compareDraftAndReport(draft: DraftReport, report: IReport): boolean {
  const draftKeys = Object.keys(draft).filter((key) => key !== "draftId")
  const reportKeys = Object.keys(report).filter((key) => key !== "draftId")
  // key length check
  if (draftKeys.length !== reportKeys.length) return false

  for (const key in draft) {
    const draftValue = draft[key as keyof DraftReport]
    const reportValue = report[key as keyof IReport]
    // console.log("key: ", key, "draft: ", draftValue, "report: ", reportValue)
    if (draftValue === undefined || reportValue === undefined) {
      if (draftValue !== reportValue) return false
      continue
    }
    if (key === "questions") {
      if (!compareQuestions(draftValue as IReportQuestion[], reportValue as IReportQuestion[])) return false
      continue
    }
    if (draftValue !== reportValue) return false
  }
  return true
}

function compareQuestions(draft: IReportQuestion[], report: IReportQuestion[]): boolean {
  if (draft.length !== report.length) return false

  for (const draftQuestion of draft) {
    const reportQuestion = report.find((question) => question.id === draftQuestion.id)
    if (!reportQuestion) return false
    if (!compareQuestion(draftQuestion, reportQuestion)) return false
  }
  return true
}

function compareQuestion(draft: IReportQuestion, report: IReportQuestion): boolean {
  const draftKeys = Object.keys(draft)
  const reportKeys = Object.keys(report)
  if (draftKeys.length !== reportKeys.length) return false

  for (const key in draft) {
    const draftValue = draft[key as keyof IReportQuestion]
    const reportValue = report[key as keyof IReportQuestion]
    if (draftValue === undefined || reportValue === undefined) {
      if (draftValue !== reportValue) return false
      continue
    }
    if (key === "image_file") {
      const draftFile = draftValue as File | null
      const reportFile = reportValue as File | null
      if (draftFile !== null && reportFile !== null) {
        if (draftFile.name !== reportFile.name) return false
      } else if (draftValue !== reportValue) {
        // どちらかが null の場合
        return false
      }
      continue
    }
    if (draftValue !== reportValue) return false
  }
  return true
}

function formatOperatorOther(OperatorOthers: string | IOperatorOther[] | undefined) {
  if (OperatorOthers === undefined) return
  return Array.isArray(OperatorOthers)
    ? OperatorOthers.map((obj) => {
        // other_numberがnull, "", またはundefinedの場合は0を使用する
        const otherNumber = obj.other_number === null || obj.other_number === undefined ? 0 : obj.other_number
        return `operator_other_name: ${obj.other_name}, operator_other_number: ${otherNumber}`
      }).join("; ")
    : OperatorOthers
}

export default App
