import React from "react"
import Container from "react-bootstrap/Container"
import FooterLogo from "@/img/footer_logo.png"

export default () => {
  return (
    <footer id="footer" className="bg-light border-top">
      <Container fluid className="text-end">
        <img src={FooterLogo} />
      </Container>
    </footer>
  )
}
