import React, { useContext } from "react"
import { Link } from "react-router-dom"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { AuthContext } from "@/lib/auth"

function App(props: any) {
  const auth = useContext(AuthContext)
  const handleDelete = () => {
    props.setDeleteModalShow(true)
    props.setDelReportId(props.report.id)
  }

  return (
    <>
      <Row className={`border-bottom text-center align-items-center py-2 ${(props.index / 2) % 1 ? "" : "bg-light"}`}>
        <Col className="px-1">{props.report.department_name}</Col>
        <Col className="px-1">
          {props.report.job_category}-{props.report.job_number}
          {props.report.job_code1 && `-${props.report.job_code1}`}
          {props.report.job_code2 && `-${props.report.job_code2}`}
        </Col>
        <Col className="px-1">{props.report.job_date}</Col>
        <Col className="px-1">{props.report.construction}</Col>
        <Col className="px-1">{props.report.construction_period_start}</Col>
        <Col className="px-1">{props.report.construction_period_end}</Col>
        <Col className="px-1">{props.report.update_date}</Col>
        <Col md>
          <Row className="mt-3 mt-md-0">
            <Col xs={4} md={12} className="">
              <Link
                to={`/report/${props.report.id}`}
                target="_blank"
                className="btn btn-primary btn-sm px-1 w-50 xs-w-100"
              >
                詳細
              </Link>
            </Col>
            {props.report.user_id === auth?.currentUser?.attributes?.sub && (
              <>
                <Col xs={4} md={12} className="">
                  <Link
                    to={`/report/${props.report.id}/edit`}
                    target="_blank"
                    className="btn btn-warning btn-sm px-1 mt-0 mt-md-1 w-50 xs-w-100"
                  >
                    更新
                  </Link>
                </Col>

                <Col xs={4} md={12} className="">
                  <Button
                    onClick={() => handleDelete()}
                    className="btn btn-danger btn-sm px-1 mt-0 mt-md-1 w-50 xs-w-100"
                  >
                    削除
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default App
