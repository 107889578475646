import * as apigw from "./apigw"
import { IReport, IReportSearch, IBeforeReportSearch, DraftReport } from "./interface"
import axios from "axios"

export const getLoginInfoDb = async (token?: string, opts?: any): Promise<any> => {
  const res = await apigw.noauthRequest("/user/get-login_info", { token }, opts)
  return res
}

export const getWeathersDb = async (opts?: any): Promise<any> => {
  const res = await apigw.request("/user/get-weathers", opts)
  return res
}

export const getContractsDb = async (opts?: any): Promise<any> => {
  const res = await apigw.request("/user/get-contracts", opts)
  return res
}

export const getDepartmentsDb = async (opts?: any): Promise<any> => {
  const res = await apigw.request("/user/get-departments", opts)
  return res
}

export const getConstructionsDb = async (opts?: any): Promise<any> => {
  const res = await apigw.request("/user/get-constructions", opts)
  return res
}

export const getConstructionDetailsDb = async (opts?: any): Promise<any> => {
  const res = await apigw.request("/user/get-construction-details", opts)
  return res
}

export const getInspectionsDb = async (opts?: any): Promise<any> => {
  const res = await apigw.request("/user/get-inspections", opts)
  return res
}

export const getOperatorsDb = async (opts?: any): Promise<any> => {
  const res = await apigw.request("/user/get-operators", opts)
  return res
}

export const getQuestionsDb = async (opts?: any): Promise<any> => {
  const res = await apigw.request("/user/get-questions", opts)
  return res
}

export const setReportDb = async (report: IReport, opts?: any): Promise<any> => {
  const res = await apigw.request("/user/set-report", report, opts)
  return res
}

export const updateReportDb = async (report: IReport, opts?: any): Promise<any> => {
  const res = await apigw.request("/user/update-report", report, opts)
  return res
}

export const getReportDb = async (id: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/user/get-report", { id }, opts)
  return res
}

export const getReportsDb = async (
  type: string,
  order: string,
  reportSearch: IReportSearch,
  offset?: number,
  opts?: any
): Promise<any> => {
  const res = await apigw.request("/user/get-reports", { type, order, reportSearch, offset }, opts)
  return res
}

export const getBeforeReportDb = async (
  type: string,
  order: string,
  beforeReportSearch: IBeforeReportSearch,
  offset?: number,
  opts?: any
): Promise<any> => {
  const res = await apigw.request("/user/get-before-report", { type, order, beforeReportSearch, offset }, opts)
  return res
}

export const delReportDb = async (id: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/user/del-report", { id }, opts)
  return res
}

export const getImageUploadUrl = async (type: string, ext: string) => {
  const res = await apigw.request<any>("/user/get-image-upload-url", { type, ext })
  return res
}

export const putFile = async (url: string, file: any, opts?: any) => {
  return axios({
    method: "PUT",
    url: url,
    headers: {
      "Content-Type": file.type,
    },
    data: file,
    ...opts,
  })
}

export const setDraftDb = async (report: DraftReport, opts?: any): Promise<any> => {
  const res = await apigw.request("/user/set-draft", report, opts)
  return res
}

export const getDraftsDb = async (offset: number, opts?: any): Promise<any> => {
  const res = await apigw.request("/user/get-drafts", { offset }, opts)
  return res
}

export const getDraftDb = async (id: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/user/get-draft", { id }, opts)
  return res
}

export const delDraftDb = async (id: string, opts?: any): Promise<any> => {
  const res = await apigw.request("/user/del-draft", { id }, opts)
  return res
}

export const delImage = async (key: string, opts?: any) => {
  const res = await apigw.request("/user/del-image", { key }, opts)
  return res
}
