import React, { useState, useEffect, createContext } from "react"
import Auth, { CognitoUser } from "@aws-amplify/auth"
import { Hub, HubCallback } from "@aws-amplify/core"

export interface IAuthContext {
  currentUser: any
  isAuthenticated: boolean
  idToken: string | null
  accessToken: string | null
  refreshToken: string | null
  signIn(username: string, password: string): Promise<CognitoUser>
  signOut(): Promise<any>
  updateUserAttributes(user: any, attributes: object): Promise<string>
  currentAuthenticatedUser(): Promise<any>
}

export interface IWechat {
  appId: string
  accessToken: string
}

export interface ITwitter {
  appName: string
  accessToken: string
}

export interface IInstagram {
  id: string
}

const signIn = (username: string, password: string): Promise<CognitoUser> => {
  return Auth.signIn(username, password)
}

const signOut = (): Promise<any> => {
  return Auth.signOut()
}

const updateUserAttributes = (user: any, attributes: object): Promise<string> => {
  return Auth.updateUserAttributes(user, attributes)
}

const currentAuthenticatedUser = (): Promise<any> => {
  return Auth.currentAuthenticatedUser()
}

const AuthContext = createContext<IAuthContext>({
  currentUser: null,
  isAuthenticated: false,
  idToken: null,
  accessToken: null,
  refreshToken: null,
  signIn,
  signOut,
  updateUserAttributes,
  currentAuthenticatedUser,
})

const AuthProvider = (props: any) => {
  const [currentUser, setCurrentUser] = useState<any>(null)
  const [idToken, setIdToken] = useState<string | null>(null)
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [refreshToken, setRefreshToken] = useState<string | null>(null)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  const authListener: HubCallback = ({ payload: { event } }) => {
    switch (event) {
      case "signIn":
        Auth.currentUserInfo().then(setCurrentUser)
        break
      case "signOut":
        setCurrentUser(null)
        setIdToken(null)
        setAccessToken(null)
        setIdToken(null)
        break
    }
  }

  useEffect(() => {
    setIsAuthenticated(currentUser !== null)
  }, [currentUser])

  useEffect(() => {
    if (isLoaded) {
      props.onLoaded(true)
    }
  }, [isLoaded])

  useEffect(() => {
    if (isLoaded === false) {
      Auth.currentSession()
        .then((session) => {
          if (session && session.isValid()) {
            setIdToken(session.getIdToken().getJwtToken())
            setAccessToken(session.getAccessToken().getJwtToken())
            setRefreshToken(session.getRefreshToken().getToken())
            Auth.currentUserInfo()
              .then((user) => {
                setCurrentUser(user)
                setIsLoaded(true)
              })
              .catch(() => {
                setIsLoaded(true)
              })
          } else {
            setIsLoaded(true)
          }
        })
        .catch(() => {
          setIsLoaded(true)
        })
    }
  }, [isLoaded])

  useEffect(() => {
    Hub.listen("auth", authListener)
    return () => Hub.remove("auth", authListener)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        currentUser: currentUser,
        isAuthenticated: isAuthenticated,
        idToken: idToken,
        accessToken: accessToken,
        refreshToken: refreshToken,
        signIn: signIn,
        signOut: signOut,
        updateUserAttributes: updateUserAttributes,
        currentAuthenticatedUser: currentAuthenticatedUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
