import * as api from "@/lib/api"
import { Table, Button } from "react-bootstrap"
import { errorHandle } from "@/lib/system"
import React, { useState, useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import { DraftRecord, isArrayDraftRecords } from "@/lib/interface"
import DeleteDraftModal from "@/pages/report/_delete_draft_modal"

const GET_DRAFTS_OFFSET = 50

function App() {
  const [delDraftId, setDelDraftId] = useState<string>("")
  const [show, deleteModalShow] = useState<boolean>(false)
  const [drafts, setDrafts] = useState<DraftRecord[]>([])
  const [offset, setOffset] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const history = useNavigate()
  const fetchDraftsFromDb = async () => {
    let offsetValue = 0
    offsetValue = offset

    try {
      const draftsJson = await api.getDraftsDb(offsetValue)
      const draftList = draftsJson.data?.drafts
      // operator_numberが空文字であればnullに変換
      if (draftList && Array.isArray(draftList)) {
        // 各operatorに対して処理を行う
        draftList.forEach((draft) => {
          draft.report.operators.forEach((operator) => {
            // operator_numberが空文字であればnullに変換
            if (operator.operator_number === "") {
              operator.operator_number = null
            }
          })
        })
      }
      const totalCount = draftsJson.data?.totalCount
      if (isArrayDraftRecords(draftList)) {
        setOffset(offsetValue + GET_DRAFTS_OFFSET)
        setTotalCount(totalCount)
        return draftList
      } else {
        console.error("Data is not DraftRecord type")
        throw new Error("Data is not DraftRecord type")
      }
    } catch (err) {
      console.error(errorHandle(err))
      throw err
    }
  }

  const updateDraftsState = async () => {
    const draftsData = await fetchDraftsFromDb()
    setDrafts([...drafts, ...draftsData])
  }

  useEffect(() => {
    updateDraftsState()
  }, [history])

  const delDraftReport = async (id: string) => {
    try {
      await api.delDraftDb(id)
      setDrafts(drafts.filter((draft) => draft.id !== id))
    } catch (err) {
      console.error(errorHandle(err))
      throw err
    }
  }

  return (
    <>
      <DeleteDraftModal
        draftId={delDraftId}
        onHide={() => deleteModalShow(false)}
        show={show}
        delDraftReport={delDraftReport}
      />
      <h1 className="h4">安全パトロール用紙下書き保存一覧</h1>
      {drafts.length === 0 ? (
        <p>下書きが見つかりませんでした。</p>
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>作成日</th>
                <th>操作</th>
                <th>削除</th>
              </tr>
            </thead>
            <tbody>
              {drafts.map((draft: DraftRecord) => {
                return (
                  <tr key={draft.id}>
                    <td>{draft.create_date ? formatToJapaneseDate(draft.create_date) : ""}</td>
                    <td>
                      {draft.id !== undefined &&
                        (draft.report?.id ? (
                          <Link to={`/report/${draft.report.id}/edit`} state={{ draftId: draft.id }}>
                            編集途中から再開
                          </Link>
                        ) : (
                          <Link to={`/report/create`} state={{ draftId: draft.id }}>
                            下書きを使ってレポートを作成
                          </Link>
                        ))}
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={async () => {
                          if (draft.id !== undefined) {
                            deleteModalShow(true)
                            setDelDraftId(draft.id)
                          }
                        }}
                      >
                        下書きを削除
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          {totalCount > offset && (
            <Button onClick={async () => await updateDraftsState()} className="btn-sm btn-block btn-primary mt-2">
              次へ
            </Button>
          )}
        </>
      )}
    </>
  )
}

function formatToJapaneseDate(date: string) {
  const jstDate = new Date(date)

  const year = jstDate.getUTCFullYear()
  const month = String(jstDate.getUTCMonth() + 1).padStart(2, "0") // 月は0から始まるため、1を加算
  const day = String(jstDate.getUTCDate()).padStart(2, "0")
  const hour = String(jstDate.getUTCHours()).padStart(2, "0")
  const minute = String(jstDate.getUTCMinutes()).padStart(2, "0")

  return `${year}/${month}/${day} ${hour}:${minute}`
}

export default App
