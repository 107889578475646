import React, { useContext } from "react"
import { Navigate } from "react-router-dom"
import { AuthContext } from "./auth"
import { Header, Footer, Scroll } from "../pages/common"

const signInPathName = "/auth/signin"

function App({ Component, ...rest }: any) {
  const auth = useContext(AuthContext)

  let route = <React.Fragment>{Component}</React.Fragment>

  if (rest.contents) {
    route = <>{route}</>
  }

  if (rest.id) {
    route = (
      <div id={rest.id} className="container-fluid p-4">
        {route}
      </div>
    )
  }

  // wrap
  route = (
    <>
      {(rest.wrap || rest.header) && rest.id != "auth-signin" && <Header {...rest} />}
      {route}
      <Scroll />
      {(rest.wrap || rest.footer) && rest.id != "auth-signin" && <Footer {...rest} />}
    </>
  )

  // authenticate
  if (rest.auth) {
    if (!auth.isAuthenticated) {
      return (
        <Navigate
          to={{
            pathname: signInPathName,
          }}
        />
      )
    }
  }

  return route
}

export default App
