import {
  IOperator,
  IOperatorError,
  IOption,
  IReport,
  IReportQuestion,
  ICommentError,
  OldImages,
  IOperatorOther,
} from "@/lib/interface"
import { changeDateString } from "@/lib/system"
import { parseComments, parseStringToDecimal, removeQueryString } from "@/lib/utils"
import dayjs from "dayjs"
import "dayjs/locale/ja"
import React from "react"
import * as api from "@/lib/api"

dayjs.locale("ja")
const today = new Date()

export const initialReport = {
  id: "",
  job_category: "",
  job_number: "",
  job_code1: "",
  job_code2: "",
  job_date: `${changeDateString(today)}T00:00`,
  weather_id: "",
  temperature: "",
  construction: "",
  //デフォルト値を[非選択]のidとする
  contract_id: "f7617df3-30c4-62b1-acc8-272f47f88112",
  contract_amount: "",
  construction_period_start: changeDateString(today),
  construction_period_end: changeDateString(today),
  progress_rate: 0,
  department_id: "",
  office_name: "",
  construction_id: "",
  construction_detail_id: "",
  inspector: "",
  department_manager: "",
  section_manager: "",
  project_manager: "",
  questions: [],
  operators: [
    {
      operator_id: "",
      operator_number: null,
      operator_other: [{ other_name: "", other_number: null }],
    },
  ],
  construction_company: "",
  partners: [""],
  comment: [{ author: "", comment: "" }],
  request_comment: [{ author: "", comment: "" }],
  inspections: [],
}

export type ACTIONTYPE =
  | { type: "INSPECTION_INIT"; list: IOption[] }
  | { type: "QUESTION_INIT"; questions: IReportQuestion[] }
  | { type: "BEFORE_QUESTION_INIT"; questions: IReportQuestion[] }
  | { type: "JOB_CATEGORY"; payload: string }
  | { type: "JOB_NUMBER"; payload: string }
  | { type: "JOB_CODE1"; payload: string }
  | { type: "JOB_CODE2"; payload: string }
  | { type: "JOB_DATE"; payload: string }
  | { type: "WEATHER_ID"; payload: string; list: IOption[] }
  | { type: "TEMPERATURE"; payload: string }
  | { type: "CONSTRUCTION"; payload: string }
  | { type: "CONTRACT_ID"; payload: string; list: IOption[] }
  | { type: "CONTRACT_AMOUNT"; payload: string }
  | { type: "CONSTRUCTION_PERIOD_START"; payload: string }
  | { type: "CONSTRUCTION_PERIOD_END"; payload: string }
  | { type: "PROGRESS_RATE"; payload: string }
  | { type: "DEPARTMENT_ID"; payload: string; list: IOption[] }
  | { type: "CHECK_ALL_DEPARTMENTS_ID"; payload: string[] }
  | { type: "OFFICE_NAME"; payload: string }
  | { type: "CONSTRUCTION_ID"; payload: string; list: IOption[] }
  | { type: "CONSTRUCTION_DETAIL_ID"; payload: string; list: IOption[] }
  | { type: "INSPECTOR"; payload: string; index: number }
  | { type: "INSPECTOR_ADD"; payload: string }
  | { type: "INSPECTOR_DEL"; payload: string; index: number }
  | { type: "DEPARTMENT_MANAGER"; payload: string }
  | { type: "SECTION_MANAGER"; payload: string }
  | { type: "PROJECT_MANAGER"; payload: string }
  | { type: "OPERATOR_ID"; payload: string; index: number; list: IOption[] }
  | { type: "OPERATOR_OTHER"; payload: string; index: number }
  | { type: "OPERATOR_NUMBER"; payload: string; index: number }
  | { type: "OPERATOR_ADD" }
  | { type: "OPERATOR_DEL"; index: number }
  | { type: "OPERATOR_OTHER_NAME"; payload: string; index: number; otherIndex: number }
  | { type: "OPERATOR_OTHER_NUMBER"; payload: string; index: number; otherIndex: number }
  | { type: "OPERATOR_OTHER_ADD"; index: number }
  | { type: "OPERATOR_OTHER_DEL"; index: number; otherIndex: number }
  | { type: "CONSTRUCTION_COMPANY"; payload: string }
  | { type: "PARTNER"; payload: string; index: number }
  | { type: "PARTNER_ADD" }
  | { type: "PARTNER_DEL"; index: number }
  | { type: "COMMENT"; payload: string; index: number }
  | { type: "COMMENT_AUTHOR"; payload: string; index: number }
  | { type: "COMMENT_ADD" }
  | { type: "COMMENT_DEL"; index: number }
  | { type: "REQUEST_COMMENT"; payload: string; index: number }
  | { type: "REQUEST_COMMENT_AUTHOR"; payload: string; index: number }
  | { type: "REQUEST_COMMENT_ADD" }
  | { type: "REQUEST_COMMENT_DEL"; index: number }
  | { type: "INSPECTION_ID"; payload: string; list: IOption[] }
  | {
      type: "RESULT_ID"
      result_id: string
      question_id: string
    }
  | { type: "QUESTION_COMMENT"; payload: string; question_id: string }
  | { type: "QUESTION_IMAGE"; payload: File[]; question_id: string }
  | { type: "DEL_PREVIEW_QUESTION_IMAGE"; question_id: string; oldImages: OldImages[] }
  | { type: "DEL_QUESTION_IMAGE"; question_id: string }
  | { type: "VALID_JOB_CATEGORY"; payload: string }
  | { type: "VALID_JOB_NUMBER"; payload: string }
  | { type: "VALID_JOB_CODE1"; payload: string }
  | { type: "VALID_JOB_CODE2"; payload: string }
  | { type: "VALID_JOB_DATE"; payload: string }
  | { type: "VALID_WEATHER_ID"; payload: string }
  | { type: "VALID_TEMPERATURE"; payload: string }
  | { type: "VALID_CONSTRUCTION"; payload: string }
  | { type: "VALID_CONTRACT_ID"; payload: string }
  | { type: "VALID_CONTRACT_AMOUNT"; payload: string }
  | { type: "VALID_CONSTRUCTION_PERIOD_START"; payload: string }
  | { type: "VALID_CONSTRUCTION_PERIOD_END"; payload: string }
  | { type: "VALID_PROGRESS_RATE"; payload: string }
  | { type: "VALID_DEPARTMENT_ID"; payload: string }
  | { type: "VALID_OFFICE_NAME"; payload: string }
  | { type: "VALID_CONSTRUCTION_ID"; payload: string; list: IOption[] }
  | { type: "VALID_CONSTRUCTION_DETAIL_ID"; payload: string; list: IOption[] }
  | { type: "VALID_INSPECTOR"; payload: string }
  | { type: "VALID_DEPARTMENT_MANAGER"; payload: string }
  | { type: "VALID_SECTION_MANAGER"; payload: string }
  | { type: "VALID_PROJECT_MANAGER"; payload: string }
  | { type: "VALID_OPERATOR_ID"; payload: string; index: number }
  | { type: "VALID_OPERATOR_OTHER"; payload: string; index: number }
  | { type: "VALID_OPERATOR_NUMBER"; payload: string; index: number }
  | { type: "VALID_OPERATOR_ADD" }
  | { type: "VALID_OPERATOR_DEL"; index: number }
  | { type: "VALID_OPERATOR_OTHER_NAME"; payload: string; index: number; otherIndex: number }
  | { type: "VALID_OPERATOR_OTHER_NUMBER"; payload: string; index: number; otherIndex: number }
  | { type: "VALID_OPERATOR_OTHER_ADD" }
  | { type: "VALID_OPERATOR_OTHER_DEL"; index: number }
  | { type: "VALID_CONSTRUCTION_COMPANY"; payload: string }
  | { type: "VALID_PARTNER"; payload: string; index: number }
  | { type: "VALID_PARTNER_ADD" }
  | { type: "VALID_PARTNER_DEL"; index: number }
  | { type: "VALID_COMMENT"; payload: string; index: number }
  | { type: "VALID_COMMENT_AUTHOR"; payload: string; index: number }
  | { type: "VALID_REQUEST_COMMENT"; payload: string; index: number }
  | { type: "VALID_REQUEST_COMMENT_AUTHOR"; payload: string; index: number }
  | { type: "VALID_INSPECTION_ID"; payload: string }
  | { type: "REPORT_ID"; payload: string }
  | { type: "SET_REPORT"; payload: IReport }
  | { type: "SET_DRAFT_REPORT"; payload: IReport }

export const reducerFunc: React.Reducer<IReport, ACTIONTYPE> = (report: IReport, action: ACTIONTYPE): IReport => {
  let errorMessage = ""
  const numPattern = /^[0-9]+$/
  const numPatternTemperature = /^-?[0-9]+$/
  const inspectors = report.inspector ? report.inspector.split(",") : [""]
  switch (action.type) {
    // 点検分類の初期値を設定
    case "INSPECTION_INIT":
      return report

    // チェックリストの初期値セット 作業分類と部門によって変化する
    case "QUESTION_INIT": {
      //この中に作業分類に適合するチェックリストを全体チェックリストから格納する
      let addQuestions = new Array<IReportQuestion>()
      if (report.inspections) {
        const questions = action.questions.filter((question: IReportQuestion) => {
          return report.inspections && question.inspection_id && report.inspections.includes(question.inspection_id)
        })
        addQuestions = [...addQuestions, ...questions]
      }
      //この中に部門に適合するチェックリストを全体チェックリストから格納する
      if (report.department_id) {
        const questions = action.questions.filter((question: IReportQuestion) => {
          return report.department_id === question.department_id
        })
        addQuestions = [...addQuestions, ...questions]
      }
      addQuestions = addQuestions.map((question: IReportQuestion) => {
        //回答済みチェックリストの場合は属性値を置き換える
        const answedQuestion = report.questions?.find((repliedQuestion: IReportQuestion) => {
          return question.id === repliedQuestion.id
        })
        if (answedQuestion) {
          if (question.image && question.image !== "") {
            return { ...answedQuestion, image: question.image }
          }
          return answedQuestion
          // 回答済みチェックリストでない場合にはresult_id: "RESULT_SLASH"にして返却
        }
        return { ...question, result_id: "RESULT_SLASH" }
      })
      return { ...report, questions: addQuestions }
    }
    case "BEFORE_QUESTION_INIT": {
      const beforeQuestions = action.questions.map((question: IReportQuestion) => {
        return { id: question.id, result_id: question.result_id }
      })
      return { ...report, before_questions: beforeQuestions }
    }
    // JOB番号が変更された時 JOB番号2桁の入力欄
    case "JOB_CATEGORY":
      return { ...report, job_category: action.payload }
    // JOB番号が変更された時 JOB番号5桁の入力欄
    case "JOB_NUMBER":
      return { ...report, job_number: action.payload }
    // JOB番号が変更された時 JOB番号3桁の入力欄
    case "JOB_CODE1":
      return { ...report, job_code1: action.payload }
    // JOB番号が変更された時 JOB番号3桁の入力欄
    case "JOB_CODE2":
      return { ...report, job_code2: action.payload }
    // 年月日が変更された時
    case "JOB_DATE":
      return { ...report, job_date: action.payload }
    // 天候が変更された時
    case "WEATHER_ID":
      return { ...report, weather_id: action.payload }
    // 気温が変更された時
    case "TEMPERATURE":
      return { ...report, temperature: action.payload }
    // 工事名が変更された時
    case "CONSTRUCTION":
      return { ...report, construction: action.payload }
    // 請負区分が変更された時
    case "CONTRACT_ID": {
      const contracts = action.list.map((contract: IOption) => {
        return contract.id
      })
      if (contracts.includes(action.payload)) {
        return { ...report, contract_id: action.payload }
      }
      //選択肢以外の値が入れられた場合には更新しない
      return report
    }
    // 請負金額が変更された時
    case "CONTRACT_AMOUNT":
      return { ...report, contract_amount: action.payload }
    // 工期が変更された時
    case "CONSTRUCTION_PERIOD_START":
      return { ...report, construction_period_start: action.payload }
    case "CONSTRUCTION_PERIOD_END":
      return { ...report, construction_period_end: action.payload }
    //進捗率が変更された時
    case "PROGRESS_RATE": {
      if (typeof action.payload === "string") {
        const progressRate = parseInt(action.payload)
        return { ...report, progress_rate: progressRate }
      }
      return report
    }
    // 部門が変更された時
    case "DEPARTMENT_ID":
      return { ...report, department_id: action.payload }

    case "CHECK_ALL_DEPARTMENTS_ID":
      return { ...report, all_department_id: action.payload }

    // 部署が変更された時
    case "OFFICE_NAME":
      return { ...report, office_name: action.payload }
    // 工事種類が変更された時
    case "CONSTRUCTION_ID":
      return { ...report, construction_id: action.payload }
    // 工事種類その2が変更された時
    case "CONSTRUCTION_DETAIL_ID":
      return { ...report, construction_detail_id: action.payload }
    // 点検者が変更された時
    case "INSPECTOR": {
      let updatedInspectors = [...inspectors]

      if (action.index < updatedInspectors.length) {
        updatedInspectors[action.index] = action.payload
        return {
          ...report,
          inspector: updatedInspectors.join(","),
        }
      }
      updatedInspectors = [
        ...inspectors.slice(0, action.index), // action.index までの要素
        action.payload, // 新しい要素を挿入
      ]
      updatedInspectors = updatedInspectors.filter((item) => item !== "")

      return {
        ...report,
        inspector: updatedInspectors.join(","),
        errors: { ...report.errors, inspector: report.errors?.inspector || "" },
      }
    }
    case "INSPECTOR_ADD": {
      const updatedInspectors = [...inspectors, action.payload]
      return {
        ...report,
        inspector: updatedInspectors.join(","),
        errors: {
          ...report.errors,
          inspector: report.errors?.inspector || "",
        },
      }
    }
    case "INSPECTOR_DEL": {
      if (inspectors && inspectors.length <= 1) {
        return report
      }
      const updatedInspectors = [
        ...(inspectors?.filter((_inspector: string, index: number) => {
          return index !== action.index
        }) || []),
      ]
      return {
        ...report,
        inspector: updatedInspectors.join(","),
        errors: {
          ...report.errors,
          inspector: report.errors?.inspector || "",
        },
      }
    }
    // 部長が変更された時
    case "DEPARTMENT_MANAGER":
      return { ...report, department_manager: action.payload }
    // 課長が変更された時
    case "SECTION_MANAGER":
      return { ...report, section_manager: action.payload }
    // が変更された時
    case "PROJECT_MANAGER":
      return { ...report, project_manager: action.payload }
    // 被点検者が変更された時
    case "OPERATOR_ID": {
      const updateIdOperator = {
        operator_id: action.payload,
        operator_number: report.operators?.[action.index]?.operator_number,
        operator_other: report.operators?.[action.index]?.operator_other,
      }
      const updateIdOperators = report.operators?.map((operator: IOperator, index: number) => {
        return index === action.index ? updateIdOperator : operator
      })
      return { ...report, operators: updateIdOperators }
    }
    case "OPERATOR_OTHER": {
      const updateOtherOperator = {
        operator_id: report.operators?.[action.index]?.operator_id,
        operator_number: report.operators?.[action.index]?.operator_number,
        operator_other: action.payload,
      }
      const updateOtherOperators = report.operators?.map((operator: IOperator, index: number) => {
        return index === action.index ? updateOtherOperator : operator
      })
      return { ...report, operators: updateOtherOperators }
    }
    // 当日の作業員人数が変更された時
    case "OPERATOR_NUMBER": {
      const updateNumberOperator = {
        operator_id: report.operators?.[action.index]?.operator_id,
        operator_number: action.payload === "" ? null : parseInt(action.payload),
        operator_other: report.operators?.[action.index]?.operator_other,
      }
      const updateNumberOperators = report.operators?.map((operator: IOperator, index: number) => {
        return index === action.index ? updateNumberOperator : operator
      })
      return { ...report, operators: updateNumberOperators }
    }
    // 当日の作業員人数が追加された時  エラー文言用のオブジェクトerrorsも同時に追加
    case "OPERATOR_ADD":
      if (report.operators && report.operators.length >= 14) {
        return report
      }
      return {
        ...report,
        operators: [
          ...(report.operators || []),
          {
            operator_id: "",
            operator_number: null,
            operator_other: [{ other_name: "", other_number: null }],
          },
        ],
      }
    // 当日の作業員人数が削除された時 エラー文言用のオブジェクトも同時に削除
    case "OPERATOR_DEL":
      if (report.operators && report.operators.length <= 1) {
        return report
      }
      return {
        ...report,
        operators: [
          ...(report.operators?.filter((_operator: IOperator, index: number) => {
            return index !== action.index
          }) || []),
        ],
        errors: {
          ...report.errors,
          operators: [
            ...(report.errors?.operators?.filter((_operator: IOperatorError, index: number) => {
              return index !== action.index
            }) || []),
          ],
        },
      }
    case "OPERATOR_OTHER_NAME":
      return {
        ...report,
        operators: [
          ...(report.operators?.map((operator: IOperator) => {
            if (operator.operator_id === "8855b643-775c-0c5b-0456-42c3d5b799e3") {
              const checkOtherOperator = Array.isArray(operator.operator_other)
                ? operator.operator_other
                : [{ other_name: "", other_number: 0 }]
              return {
                ...operator,
                operator_other: [
                  ...checkOtherOperator.map((other: IOperatorOther, otherIndex: number) => {
                    if (otherIndex === action.index) {
                      return { ...other, other_name: action.payload }
                    }
                    return other
                  }),
                ],
              }
            }
            return operator
          }) || []),
        ],
        errors: {
          ...report.errors,
          operators: [
            ...(report.errors?.operators || []),
            {
              idMessage: "",
              numMessage: "",
              otherMessage: "",
            },
          ],
        },
      }
    case "OPERATOR_OTHER_NUMBER":
      return {
        ...report,
        operators: [
          ...(report.operators?.map((operator: IOperator) => {
            if (operator.operator_id === "8855b643-775c-0c5b-0456-42c3d5b799e3") {
              const checkOtherOperator = Array.isArray(operator.operator_other)
                ? operator.operator_other
                : [{ other_name: "", other_number: 0 }]
              return {
                ...operator,
                operator_other: [
                  ...checkOtherOperator.map((other: IOperatorOther, otherIndex: number) => {
                    if (otherIndex === action.index) {
                      return { ...other, other_number: parseStringToDecimal(action.payload) }
                    }
                    return other
                  }),
                ],
              }
            }
            return operator
          }) || []),
        ],
      }
    case "OPERATOR_OTHER_ADD":
      return {
        ...report,
        operators: [
          ...(report.operators?.map((operator: IOperator) => {
            if (operator.operator_id === "8855b643-775c-0c5b-0456-42c3d5b799e3") {
              const checkOtherOperator = Array.isArray(operator.operator_other)
                ? operator.operator_other
                : [{ other_name: "", other_number: null }]
              return {
                ...operator,
                operator_other: [
                  ...checkOtherOperator,
                  { other_name: "", other_number: null }, // 新しいIOperatorOtherオブジェクトを追加
                ],
              }
            }
            return operator
          }) || []),
        ],
        errors: {
          ...report.errors,
          operators: [
            ...(report.errors?.operators || []),
            {
              idMessage: "",
              numMessage: "",
              otherMessage: "",
            },
          ],
        },
      }
    case "OPERATOR_OTHER_DEL":
      return {
        ...report,
        operators: [
          ...(report.operators?.map((operator: IOperator) => {
            if (operator.operator_id === "8855b643-775c-0c5b-0456-42c3d5b799e3") {
              const checkOtherOperator = Array.isArray(operator.operator_other)
                ? operator.operator_other
                : [{ other_name: "", other_number: 0 }]
              return {
                ...operator,
                operator_other: [
                  ...(checkOtherOperator.filter((_other: IOperatorOther, index: number) => {
                    return index !== action.otherIndex
                  }) || []),
                ],
              }
            }
            return operator
          }) || []),
        ],
        errors: {
          ...report.errors,
          operators: [
            ...(report.errors?.operators?.filter((_operator: IOperatorError, index: number) => {
              return index !== action.index
            }) || []),
          ],
        },
      }

    // 建築会社（統括安全衛生責任者）が変更された時
    case "CONSTRUCTION_COMPANY":
      return { ...report, construction_company: action.payload }
    // 当日入場協力会社が変更された時
    case "PARTNER": {
      const updatePartners = report.partners?.map((partner: string, index: number) => {
        return index === action.index ? action.payload : partner
      })
      return { ...report, partners: updatePartners }
    }
    // 当日入場協力会社が追加された時 エラー文言用のオブジェクトも同時に追加
    case "PARTNER_ADD":
      return {
        ...report,
        partners: [...(report.partners || []), ""],
        errors: {
          ...report.errors,
          partners: [...(report.errors?.partners || []), ""],
        },
      }
    // 当日入場協力会社が削除された時 エラー文言用のオブジェクトも同時に削除
    case "PARTNER_DEL":
      if (report.partners && report.partners.length <= 1) {
        return report
      }
      return {
        ...report,
        partners: [
          ...(report.partners?.filter((_partner: string, index: number) => {
            return index !== action.index
          }) || []),
        ],
        errors: {
          ...report.errors,
          partners: [
            ...(report.errors?.partners?.filter((_partner: string, index: number) => {
              return index !== action.index
            }) || []),
          ],
        },
      }
    // 総評が変更された時
    case "COMMENT":
      if (report.comment && typeof report.comment !== "string") {
        const updateComments = report.comment.map((item, index) =>
          index === action.index ? { ...item, comment: action.payload } : item
        )
        console.log("COMMENT func", updateComments)
        // report.comment[action.index].comment = action.payload
        return { ...report, comment: updateComments }
      }
      if (report.comment && typeof report.comment === "string") {
        const updateComments = parseComments(report.comment)
        if (updateComments) {
          return { ...report, comment: updateComments }
        }
      }
      return report

    case "COMMENT_AUTHOR":
      if (report.comment && typeof report.comment !== "string") {
        report.comment[action.index].author = action.payload
      }

      return { ...report, comment: report.comment }
    case "COMMENT_ADD": {
      if (report.comment && typeof report.comment !== "string") {
        const updateComments = [...report.comment, { author: "", comment: "" }]
        return { ...report, comment: updateComments }
      }
      if (report.comment && typeof report.comment === "string") {
        const updateComments = parseComments(report.comment)
        if (updateComments) {
          return { ...report, comment: updateComments }
        }
      }
      return report
    }

    case "COMMENT_DEL": {
      if ((report.comment?.length ?? 0) <= 1) {
        return report
      }
      if (report.comment && typeof report.comment !== "string") {
        const updateComments = [...report.comment]
        updateComments.splice(action.index, 1)
        return { ...report, comment: updateComments }
      }
      return report
    }

    // 現場からの要望事項が変更された時
    case "REQUEST_COMMENT":
      if (report.request_comment && typeof report.request_comment !== "string") {
        report.request_comment[action.index].comment = action.payload
      }
      if (report.request_comment && typeof report.request_comment === "string") {
        const updateComments = parseComments(report.request_comment)
        if (updateComments) {
          return { ...report, request_comment: updateComments }
        }
      }
      return { ...report, request_comment: report.request_comment }

    case "REQUEST_COMMENT_AUTHOR":
      if (report.request_comment && typeof report.request_comment !== "string") {
        report.request_comment[action.index].author = action.payload
      }
      return { ...report, request_comment: report.request_comment }

    case "REQUEST_COMMENT_ADD": {
      if (report.request_comment && typeof report.request_comment !== "string") {
        const updateComments = [...report.request_comment, { author: "", comment: "" }]
        return { ...report, request_comment: updateComments }
      }
      if (report.request_comment && typeof report.request_comment === "string") {
        const updateComments = parseComments(report.request_comment)
        if (updateComments) {
          return { ...report, request_comment: updateComments }
        }
      }
      return report
    }

    case "REQUEST_COMMENT_DEL": {
      if ((report.request_comment?.length ?? 0) <= 1) {
        return report
      }
      if (report.request_comment && typeof report.request_comment !== "string") {
        const updateRequestComments = [...report.request_comment]
        updateRequestComments.splice(action.index, 1)
        return { ...report, request_comment: updateRequestComments }
      }
      return report
    }
    // 点検分類が変更された時
    case "INSPECTION_ID": {
      let updateInspections = []
      let updateQuestions = []
      // desabledにされた場合
      if (
        report.inspections &&
        report.questions &&
        report.inspections.find((inspectionId: string) => {
          return inspectionId === action.payload
        })
      ) {
        updateInspections = report.inspections.filter((inspectionId: string) => {
          return inspectionId !== action.payload
        })
        updateQuestions = report.questions.filter((question: IReportQuestion) => {
          return question.inspection_id !== action.payload
        })
        return {
          ...report,
          inspections: updateInspections,
          questions: updateQuestions,
        }
        // enabledにされた場合
      } else {
        let updateInspections = [...(report.inspections || []), action.payload]
        if (updateInspections.length !== 1) {
          updateInspections = updateInspections.filter((inspectionId: string) => inspectionId !== "")
        }
        return {
          ...report,
          inspections: updateInspections,
        }
      }
    }
    // チェックリストが変更された時
    case "RESULT_ID": {
      const resultIds = ["RESULT_SLASH", "RESULT_CIRCLE", "RESULT_TRIANGLE", "RESULT_CROSS"]
      if (resultIds.includes(action.result_id)) {
        const updateQuestions = report.questions?.map((reportQuestion: IReportQuestion) => {
          if (reportQuestion.id == action.question_id) {
            ///か○の場合コメントを消す
            if (["RESULT_SLASH", "RESULT_CIRCLE"].includes(action.result_id)) {
              return { ...reportQuestion, result_id: action.result_id, comment: "" }
            }
            return { ...reportQuestion, result_id: action.result_id }
          } else {
            return reportQuestion
          }
        })
        return { ...report, questions: updateQuestions }
      } else {
        //選択肢以外の値が入れられた場合には更新しない
        return report
      }
    }
    //チェックリストごとのコメント(是正内容)が変更されたとき
    case "QUESTION_COMMENT": {
      const updateQuestionComments = report.questions?.map((reportQuestion: IReportQuestion) => {
        if (reportQuestion.id == action.question_id) {
          return { ...reportQuestion, comment: action.payload }
        } else {
          return reportQuestion
        }
      })
      return { ...report, questions: updateQuestionComments }
    }
    //チェックリストごとの画像が変更されたとき
    case "QUESTION_IMAGE": {
      //画像処理
      const MAX_THUMBNAIL_SIZE = 20971520
      const MIN_THUMBNAIL_SIZE = 0
      const acceptedFiles = action.payload
      if (acceptedFiles[0].type.includes("image/")) {
        if (acceptedFiles[0].size > MIN_THUMBNAIL_SIZE && acceptedFiles[0].size <= MAX_THUMBNAIL_SIZE) {
          try {
            //これをreport.questionsごとにセットする
            const updateQuestionImages = report.questions?.map((reportQuestion: IReportQuestion) => {
              if (reportQuestion.id == action.question_id) {
                return { ...reportQuestion, image_file: acceptedFiles[0] }
              } else {
                return reportQuestion
              }
            })
            return { ...report, questions: updateQuestionImages }
          } catch (err) {
            console.log(err)
          }
        } else {
          alert("ファイルサイズは20Mまでです")
        }
      } else {
        alert("ファイル形式が不正です")
      }
      return report
    }
    //チェックリストの仮画像を削除
    case "DEL_PREVIEW_QUESTION_IMAGE": {
      const updateQuestions = report.questions?.map((reportQuestion: IReportQuestion) => {
        if (reportQuestion.id == action.question_id) {
          const oldImage = action.oldImages.filter((oldImage: OldImages) => {
            return oldImage.questionId === action.question_id
          })
          try {
            if (reportQuestion.image) {
              const formatImagePath = removeQueryString(reportQuestion.image)
              api.delImage(formatImagePath)
            }
          } catch (err) {
            console.log(err)
          }
          const imagePath = oldImage.length > 0 ? oldImage[0].imagePath : ""
          return { ...reportQuestion, image_file: null, image: imagePath }
        } else {
          return reportQuestion
        }
      })
      if (updateQuestions) {
        return { ...report, questions: updateQuestions }
      } else {
        return report
      }
    }
    //チェックリストの画像を削除
    case "DEL_QUESTION_IMAGE": {
      const updateQuestions = report.questions?.map((reportQuestion: IReportQuestion) => {
        if (reportQuestion.id == action.question_id) {
          return { ...reportQuestion, image: "" }
        } else {
          return reportQuestion
        }
      })
      if (updateQuestions) {
        return { ...report, questions: updateQuestions }
      } else {
        return report
      }
    }
    // JOB番号エラー処理 2桁
    case "VALID_JOB_CATEGORY":
      if (action.payload) {
        if (action.payload.length > 2) {
          errorMessage = "2文字以内で入力してください"
        } else if (!numPattern.test(action.payload)) {
          errorMessage = "半角数字で入力してください"
        }
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          job_category: errorMessage,
        },
      }
    // JOB番号エラー処理 5桁
    case "VALID_JOB_NUMBER":
      if (action.payload) {
        if (action.payload.length > 5) {
          errorMessage = "5文字以内で入力してください"
        } else if (!numPattern.test(action.payload)) {
          errorMessage = "半角数字で入力してください"
        }
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          job_number: errorMessage,
        },
      }
    case "VALID_JOB_CODE1":
      if (action.payload) {
        if (action.payload.length > 3) {
          errorMessage = "3文字以内で入力してください"
        } else if (!numPattern.test(action.payload)) {
          errorMessage = "半角数字で入力してください"
        }
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          job_code1: errorMessage,
        },
      }
    case "VALID_JOB_CODE2":
      if (action.payload) {
        if (action.payload.length > 3) {
          errorMessage = "3文字以内で入力してください"
        } else if (!numPattern.test(action.payload)) {
          errorMessage = "半角数字で入力してください"
        }
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          job_code2: errorMessage,
        },
      }
    // 年月日エラー処理
    case "VALID_JOB_DATE":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (!dayjs(action.payload).isValid()) {
        errorMessage = "日付が不正です"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          job_date: errorMessage,
        },
      }
    // 天候エラー処理
    case "VALID_WEATHER_ID":
      if (action.payload.length == 0) {
        console.log("blank")
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          weather_id: errorMessage,
        },
      }
    // 気温エラー処理
    case "VALID_TEMPERATURE":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 3) {
        errorMessage = "3文字以内で入力してください"
      } else if (!numPatternTemperature.test(action.payload)) {
        errorMessage = "半角数字で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          temperature: errorMessage,
        },
      }
    // 工事名エラー処理
    case "VALID_CONSTRUCTION":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 40) {
        errorMessage = "40文字以内で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          construction: errorMessage,
        },
      }
    // 請負区分エラー処理
    case "VALID_CONTRACT_ID":
      if (action.payload.length == 0) {
        console.log("blank")
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          contract_id: errorMessage,
        },
      }
    // 請負金額エラー処理
    case "VALID_CONTRACT_AMOUNT":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 11) {
        errorMessage = "3文字以内で入力してください"
      } else if (!numPattern.test(action.payload)) {
        errorMessage = "半角数字で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          contract_amount: errorMessage,
        },
      }
    // 工期エラー処理
    case "VALID_CONSTRUCTION_PERIOD_START":
      if (!action.payload || action.payload.length == 0) {
        console.log("blank")
      } else if (!dayjs(action.payload).isValid()) {
        console.log(action.payload)
        console.log("start")
        errorMessage = "日付が不正です"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          construction_period_start: errorMessage,
        },
      }
    case "VALID_CONSTRUCTION_PERIOD_END":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (!dayjs(action.payload).isValid()) {
        console.log(action.payload)
        console.log("end")

        errorMessage = "日付が不正です"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          construction_period_end: errorMessage,
        },
      }
    //進捗率
    case "VALID_PROGRESS_RATE": {
      let progressRate: number
      try {
        progressRate = parseInt(action.payload)
        if (progressRate < 0 || progressRate > 100) {
          errorMessage = "0~100の範囲で入力してください"
        }
      } catch (err) {
        errorMessage = "数値を入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          progress_rate: errorMessage,
        },
      }
    }
    // 部門エラー処理
    case "VALID_DEPARTMENT_ID":
      if (action.payload.length == 0) {
        errorMessage = "部門は選択必須です"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          department_id: errorMessage,
        },
      }
    // 部署エラー処理
    case "VALID_OFFICE_NAME":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 15) {
        errorMessage = "15文字以内で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          office_name: errorMessage,
        },
      }
    // 工事種類エラー処理
    case "VALID_CONSTRUCTION_ID":
      if (action.payload.length == 0) {
        console.log("blank")
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          construction_id: errorMessage,
        },
      }
    // 工事種類その2エラー処理
    case "VALID_CONSTRUCTION_DETAIL_ID":
      if (action.payload.length == 0) {
        console.log("blank")
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          construction_detail_id: errorMessage,
        },
      }
    // 点検者エラー処理
    case "VALID_INSPECTOR":
      if (action.payload.length == 0) {
        console.log("blank")
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          inspector: errorMessage,
        },
      }
    // 部長エラー処理
    case "VALID_DEPARTMENT_MANAGER":
      if (action.payload.length == 0) {
        console.log("blank")
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          department_manager: errorMessage,
        },
      }
    // 課長エラー処理
    case "VALID_SECTION_MANAGER":
      if (action.payload.length == 0) {
        console.log("blank")
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          section_manager: errorMessage,
        },
      }
    // 被点検者エラー処理
    case "VALID_PROJECT_MANAGER":
      if (action.payload.length == 0) {
        console.log("blank")
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          project_manager: errorMessage,
        },
      }
    // 当日の作業員種別エラー処理
    case "VALID_OPERATOR_ID": {
      if (action.payload.length == 0) {
        console.log("blank")
      }

      const defaultErrorObject = { idMessage: "", numMessage: "", otherMessage: "" }

      // report.errors.operators 配列のコピーを作成し、null の要素を置き換え
      const updateErrorsOperators = (report.errors?.operators ?? []).map((operator) =>
        operator === null ? defaultErrorObject : operator
      )

      updateErrorsOperators[action.index] = {
        idMessage: errorMessage,
        numMessage: updateErrorsOperators[action.index]?.numMessage || "",
        otherMessage: updateErrorsOperators[action.index]?.otherMessage || "",
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          operators: updateErrorsOperators,
        },
      }
    }
    // 当日の作業員人数エラー処理
    case "VALID_OPERATOR_NUMBER": {
      if (action.payload === "") {
        console.log("blank")
      } else if (action.payload.length > 10) {
        errorMessage = "10文字以内で入力してください"
      } else if (!numPattern.test(action.payload)) {
        errorMessage = "半角数字で入力してください"
      }
      if (report.errors?.operators) {
        const updateErrorsOperators = report.errors.operators.slice()
        updateErrorsOperators[action.index] = {
          idMessage: updateErrorsOperators[action.index]?.idMessage || "",
          numMessage: errorMessage,
          otherMessage: updateErrorsOperators[action.index]?.otherMessage || "",
        }
        return {
          ...report,
          errors: {
            ...report.errors,
            operators: updateErrorsOperators,
          },
        }
      } else {
        return report
      }
    }
    case "VALID_OPERATOR_OTHER_NAME": {
      if (action.payload.length > 30) {
        errorMessage = "30文字以内で入力してください"
      }
      if (report.errors?.operators) {
        const updateErrorsOperators = report.errors.operators.slice()
        updateErrorsOperators[action.index] = {
          idMessage: updateErrorsOperators[action.index]?.idMessage || "",
          numMessage: updateErrorsOperators[action.index]?.numMessage || "",
          otherMessage: errorMessage,
        }
        return {
          ...report,
          errors: {
            ...report.errors,
            operators: updateErrorsOperators,
          },
        }
      }
      return report
    }
    case "VALID_OPERATOR_OTHER_NUMBER": {
      if (action.payload === "") {
        console.log("blank")
      } else if (action.payload.length > 10) {
        errorMessage = "10文字以内で入力してください"
      } else if (!numPattern.test(action.payload)) {
        errorMessage = "半角数字で入力してください"
      }
      if (report.errors?.operators) {
        const updateErrorsOperators = report.errors.operators.slice()
        updateErrorsOperators[action.index] = {
          idMessage: updateErrorsOperators[action.index]?.idMessage || "",
          numMessage: errorMessage || "",
          otherMessage: updateErrorsOperators[action.index]?.otherMessage || "",
        }
        return {
          ...report,
          errors: {
            ...report.errors,
            operators: updateErrorsOperators,
          },
        }
      }
      return report
    }
    // 建築会社（統括安全衛生責任者）エラー処理
    case "VALID_CONSTRUCTION_COMPANY":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 15) {
        errorMessage = "15文字以内で入力してください"
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          construction_company: errorMessage,
        },
      }
    // 当日入場協力会社エラー処理
    case "VALID_PARTNER":
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 30) {
        errorMessage = "30文字以内で入力してください"
      }
      if (report.errors?.operators && report.errors?.partners) {
        const updateErrorsPartners = report.errors.partners.slice()
        updateErrorsPartners[action.index] = errorMessage
        return {
          ...report,
          errors: {
            ...report.errors,
            partners: updateErrorsPartners,
          },
        }
      } else {
        return report
      }
    // 総評エラー処理
    case "VALID_COMMENT_AUTHOR": {
      const newCommentErrorMessage = [...(report.errors?.comment || [])]
      let commentErrorMessage = ""
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 50) {
        commentErrorMessage = "50文字以内で入力してください"
      } else if (containsSpecialCharacter(action.payload)) {
        commentErrorMessage = "使用できない記号が含まれています"
      }
      newCommentErrorMessage[action.index] = {
        ...newCommentErrorMessage[action.index],
        author: commentErrorMessage,
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          comment: newCommentErrorMessage,
        },
      }
    }
    case "VALID_COMMENT": {
      const newCommentErrorMessage = [...(report.errors?.comment || [])]
      let commentErrorMessage = ""
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 100) {
        commentErrorMessage = "100文字以内で入力してください"
      } else if (containsSpecialCharacter(action.payload)) {
        commentErrorMessage = "使用できない記号が含まれています"
      }
      newCommentErrorMessage[action.index] = {
        ...newCommentErrorMessage[action.index],
        comment: commentErrorMessage,
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          comment: newCommentErrorMessage,
        },
      }
    }
    // 現場からの要望事項エラー処理
    case "VALID_REQUEST_COMMENT_AUTHOR": {
      const newCommentErrorMessage = [...(report.errors?.request_comment || [])]
      let commentErrorMessage = ""
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 50) {
        commentErrorMessage = "50文字以内で入力してください"
      } else if (containsSpecialCharacter(action.payload)) {
        commentErrorMessage = "使用できない記号が含まれています"
      }
      newCommentErrorMessage[action.index] = {
        ...newCommentErrorMessage[action.index],
        author: commentErrorMessage,
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          request_comment: newCommentErrorMessage,
        },
      }
    }
    case "VALID_REQUEST_COMMENT": {
      const newCommentErrorMessage = [...(report.errors?.request_comment || [])]
      let commentErrorMessage = ""
      if (action.payload.length == 0) {
        console.log("blank")
      } else if (action.payload.length > 100) {
        commentErrorMessage = "100文字以内で入力してください"
      } else if (containsSpecialCharacter(action.payload)) {
        commentErrorMessage = "使用できない記号が含まれています"
      }
      newCommentErrorMessage[action.index] = {
        ...newCommentErrorMessage[action.index],
        comment: commentErrorMessage,
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          request_comment: newCommentErrorMessage,
        },
      }
    }
    // 点検分類エラー処理
    case "VALID_INSPECTION_ID":
      if (action.payload.length == 0) {
        console.log("blank")
      }
      return {
        ...report,
        errors: {
          ...report.errors,
          inspections: errorMessage,
        },
      }
    // 新規登録にレポートIDをセット 次保存時には更新とするため
    case "REPORT_ID":
      if (action.payload) {
        return {
          ...report,
          id: action.payload,
        }
      } else {
        return report
      }
    // reportデータをセット、ない場合にはデフォルトの値
    case "SET_REPORT":
      if (action.payload) {
        return action.payload
      } else {
        return initialReport
      }
    case "SET_DRAFT_REPORT":
      if (action.payload) {
        return action.payload
      } else {
        return initialReport
      }
    default:
      return report
  }
}

export const errorCounter = (report: IReport): number => {
  let errorCount = 0
  //部門チェック
  if (report.department_id === "") {
    errorCount++
  }
  //単数系<string>のエラーの数をインクリメント
  if (report.errors) {
    Object.values(report.errors)?.forEach((value: string | number | IOperatorError[] | string[]) => {
      //typeでエラー内容を判定する string or string[] or IOperatorError[]
      if (typeof value === "string" && value !== "") {
        errorCount++
      }
    })
  }
  //operators<IOperatorError[]>のエラーの数をインクリメント
  report.errors?.operators?.forEach((operatorError: IOperatorError) => {
    if (operatorError.idMessage !== "") {
      errorCount++
    }
    if (operatorError.numMessage !== "") {
      errorCount++
    }
    if (operatorError.otherMessage !== "") {
      errorCount++
    }
  })

  // partners<string[]>のエラーの数をインクリメント
  report.errors?.partners?.forEach((partnerError: string) => {
    if (partnerError !== "") {
      errorCount++
    }
  })

  // comment<ICommentError[]>のエラーの数をインクリメント
  report.errors?.comment?.forEach((commentError: ICommentError) => {
    if (commentError.author && commentError.author !== "") {
      errorCount++
    }
    if (commentError.comment && commentError.comment !== "") {
      errorCount++
    }
  })
  // request_comment<ICommentError[]>のエラーの数をインクリメント
  report.errors?.request_comment?.forEach((request_commentError: ICommentError) => {
    if (request_commentError.author && request_commentError.author !== "") {
      errorCount++
    }
    if (request_commentError.comment && request_commentError.comment !== "") {
      errorCount++
    }
  })
  return errorCount
}

export function containsSpecialCharacter(str: string): boolean {
  const specialCharacters = [",", ":", ";", '"', "'"]
  return specialCharacters.some((char) => str.includes(char))
}
